import { TabContext } from "@mui/lab";
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { isEqual } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { CombineShipmentsModal } from "./components/CombineShipmentsModal";
import { ShipmentsDataGrid } from "./components/ShipmentsDataGrid";
import { VisibilitySwitch } from "./components/VisibilitySwitch";
import { WeekCalendar } from "./components/week-calendar/WeekCalendar";
import { backofficeShipmentColumns } from "./constants/backofficeShipmentsColumns";
import { useShipmentQueries } from "./hooks/useShipmentQueries";
import DialogCombinedShipmentsWarning from "./components/details-drawer/DialogCombinedShipments";
import DialogConfirmForShipments from "./components/details-drawer/DialogConfirmForShipments";
import { useTenant } from "src/hooks/useTenant";

const initialPageState = {
    selectedCalendarDate: new Date().setHours(0, 0, 0, 0),
    isCalendarVisible: false,
    selectedTab: 'opened',
}

const tabs = [
    {
        index: "opened",
        title: "Opened",
        iconComponent: null,
    },
];

export default function BackofficeShipmentsPage() {
    const { session } = useAuth();
    const { tenant } = useTenant();
    const {
        pendingShipment,
        getBackofficeShipments,
        backofficeShipments,
        isLoadingBackofficeShipments,
    } = useShipmentQueries();

    const { shipmentId } = useParams();

    const [pageState, setPageState] = useState(initialPageState);
    const [selectedRowState, setRowSelected] = useState({
        shipmentIds: [],
        shipments: [],
    });
    const [combinedModalState, setCombinedModalState] = useState({
        isOpen: false,
        isValidationOpen: false,
        message: '',
        tenants: [],
    })

    const onSelectedDateChange = (selectedDay) => {
        setPageState({ ...pageState, selectedCalendarDate: selectedDay });
    };

    const toggleCalendarVisibility = () => {
        setPageState({ ...pageState, isCalendarVisible: !pageState.isCalendarVisible });
    }

    const onTabSelected = (tab) => {
        setPageState({ ...pageState, selectedTab: tab });
    };

    const intervalRef = useRef(null);

    const [openDialogToPending, setOpenDialogToPending] = useState(false)

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        getBackofficeShipments(pageState.selectedTab)
        intervalRef.current = setInterval(() => getBackofficeShipments(pageState.selectedTab), combinedModalState.isOpen ? 10000000 : 10000);

        return () => clearInterval(intervalRef.current);
    }, [combinedModalState.isOpen]);


    // Filtering the data on user actions
    const filteredData = useMemo(() => {
        let shipments = backofficeShipments;
        if (!Array.isArray(shipments)) return [];

        if (pageState.isCalendarVisible && pageState.selectedCalendarDate) {
            return shipments.filter((shipment) => {
                const deliveryDate = new Date(
                    shipment.stops[0].targetDeliveryLate
                ).setHours(0, 0, 0, 0);

                const shipDate = new Date(
                    shipment.pickups[0].targetShipEarly
                ).setHours(0, 0, 0, 0);

                const currentDate = pageState.selectedCalendarDate;

                return isEqual(deliveryDate, currentDate) || isEqual(shipDate, currentDate);
            });
        }
        return shipments;

    }, [backofficeShipments, pageState.isCalendarVisible, pageState.selectedCalendarDate])

    const [shipmentIdPending, setShipmentIdPending] = useState(null);

    const onPending = (e, shipment) => {
        e.stopPropagation();
        setOpenDialogToPending(true)
        setShipmentIdPending(shipment)
    }

    const setPendingShipment = (rate) => {
        if (rate) {
            rate = String(rate).replace(/,/g, '');
            rate = parseFloat(rate);
        }
        pendingShipment({ shipmentId: shipmentIdPending, rate: rate }, {
            onSuccess: () => {
                backofficeShipments.filter(sh => sh._id !== shipmentIdPending)
            }
        });
    }

    const columns = backofficeShipmentColumns(session, tenant, onPending);
    const onRowSelected = (ids) => {
        const shipments = !ids || !ids.length ? [] : filteredData.filter(shipment => ids.includes(shipment._id));
        setRowSelected({
            shipmentIds: ids,
            shipments,
        });
    };

    const onMultipleTenantSelected = (tenants) => {
        setCombinedModalState({
            isOpen: false,
            isValidationOpen: true,
            message: `You're about to combine ${tenants[0]} and ${tenants[1]} shipments. Are you sure you want to proceed?`,
        });
    };

    const onCombineShipmentInDiffTenant = (shipmentTenant, platformTenant) => {
        setCombinedModalState({
            isOpen: false,
            isValidationOpen: true,
            message: `You're about to combine ${shipmentTenant} shipment under ${platformTenant} tenant. Are you sure you want to proceed?`,
        });
    }

    const openCombineModal = () => setCombinedModalState({ ...combinedModalState, isOpen: true });
    const closeCombineModal = () => setCombinedModalState({ isValidationOpen: false, isOpen: false, tenants: [] });

    const onCombineTapped = () => {
        const tenants = selectedRowState.shipments.map(shipment => shipment.tenant.code);
        const uniqueTenants = [...new Set(tenants)];

        if (uniqueTenants.length > 1) {
            if (!uniqueTenants.includes(tenant.code)) {
                return onCombineShipmentInDiffTenant(uniqueTenants[0], tenant.code);
            }

            return onMultipleTenantSelected(uniqueTenants);
        }

        if (!uniqueTenants.includes(tenant.code)) {
            return onCombineShipmentInDiffTenant(uniqueTenants[0], tenant.code);
        }

        openCombineModal();
    };

    function DataGridHeaderActionsButtons() {
        const isSelectionValidToCombine = selectedRowState.shipmentIds.length > 1;

        return (
            <Box gap={1}
                sx={{
                    button: { fontSize: '0.8rem' },
                    display: 'flex',
                }}
            >
                {tabs.map((item) => (
                    <Button
                        variant={item.index == pageState.selectedTab ? "outlined" : "text"}
                        key={item.index}
                        sx={{ height: '2rem' }}
                        onClick={() => onTabSelected(item.index)}
                    >{item.title}</Button>
                ))}

                {/* COMBINE SELECTED SHIPMENTS */}
                {isSelectionValidToCombine &&
                    <Button
                        variant={pageState.selectedTab == "combined" ? "outlined" : "text"}
                        onClick={onCombineTapped}
                        sx={{ maxHeight: 'fit-content' }}
                        disabled={!isSelectionValidToCombine}
                    > Combine Selection</Button>
                }
            </Box>
        )
    }

    return (
        <>
            <Helmet>
                <title> All Shipments | {session.tenant.title} </title>
            </Helmet>
            <Container maxWidth="false">
                <Typography
                    variant="h4"
                    sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
                >
                    All Shipments
                </Typography>
                <Box sx={{ my: 4, display: { md: "none" } }} />
                <Grid container item xs={12}>
                    <Card sx={{ p: 2 }}>
                        <VisibilitySwitch
                            label="Filter by Day"
                            isVisible={!pageState.isCalendarVisible}
                            setVisibility={toggleCalendarVisibility}
                        >
                            <WeekCalendar
                                startingDate={pageState.selectedCalendarDate}
                                onClickHandler={onSelectedDateChange}
                            />
                        </VisibilitySwitch>
                        <TabContext value={pageState.selectedTab}>
                            <section className="dataTable">
                                <ShipmentsDataGrid
                                    shipments={filteredData}
                                    isLoadingData={isLoadingBackofficeShipments}
                                    columns={columns}
                                    selectedItemId={shipmentId}
                                    customToolbarActions={DataGridHeaderActionsButtons}
                                    disableMultipleRowSelection={!['combined', 'closed'].includes(pageState.selectedTab)}
                                    treeData={pageState.selectedTab == 'combined' && !isLoadingBackofficeShipments}
                                    getTreeDataPath={(row) => row.hierarchy || []}
                                    defaultGroupingExpansionDepth={1}
                                    onRowSelectionModelChange={(selectedItemsIds, details) => onRowSelected(selectedItemsIds)}
                                    modelKey={'shipments'}
                                />
                            </section>
                        </TabContext>
                    </Card>
                </Grid>
                <DialogCombinedShipmentsWarning
                    onConfirm={closeCombineModal}
                    onClose={closeCombineModal}
                    open={combinedModalState.isValidationOpen}
                    message={combinedModalState.message}
                />
                <CombineShipmentsModal
                    shipments={selectedRowState.shipments}
                    open={combinedModalState.isOpen}
                    handleClose={closeCombineModal}
                />
                <DialogConfirmForShipments
                    inputRate
                    title={"Add to Pending"}
                    text={"Are you sure you want to set this shipment as Pending?"}
                    onConfirm={(e) => {
                        setOpenDialogToPending(false);
                        setPendingShipment(e);
                    }}
                    onClose={() => { setOpenDialogToPending(false) }}
                    open={openDialogToPending} />
            </Container>
        </>
    );
}