export const status = [
  {
    label: "Must go today",
    key: "must_go_today",
    color: "#FFFFFF",
    backgroundColor: "#f75d5d",
  },
  {
    label: "Aging load",
    key: "aging_load",
    color: "#ff0000",
    backgroundColor: "#E3F2FD",
  },
  {
    label: "Today only",
    key: "today_only",
    color: "#333333",
    backgroundColor: "#D0EAFD",
  },
  {
    label: "Today & Tomorrow",
    key: "today_and_tomorrow",
    fontWeight: "bold",
    color: "#333333",
    backgroundColor: "#CCE7F3",
  },
  {
    label: "Quotations Only",
    key: "quotations_only",
    color: "#107C41",
    backgroundColor: "#D4F4DD",
    fontWeight: "bold",
  },
  {
    label: "Need to update (DAT & TS)",
    key: "need_update_dat_ts",
    color: "#333333",
    backgroundColor: "#FFE066",
  },
  {
    label: "Need to post (DAT & TS)",
    key: "need_post_dat_ts",
    color: "#333333",
    backgroundColor: "#FFE066",
  },
  {
    label: "Must be deleted (DAT & TS)",
    key: "must_be_deleted_dat_ts",
    color: "#FFFFFF",
    backgroundColor: "#f75d5d",
  },
];
