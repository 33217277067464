import { Box, Typography } from "@mui/material";
import { useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ShipmentService } from "src/api/shipment.service";
import { ShipmentCarrierService } from "src/api/shipmentCarrier.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useSystem } from "src/hooks/useSystem";
import { ShipmentUtils } from "src/utils";

export function useShipmentQueries() {
  const { socket } = useSystem();
  const { session } = useAuth();
  const service = new ShipmentService(session.token, session.tenant._id);
  const carrierService = new ShipmentCarrierService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();

  const navigate = useNavigate();

  const filterRef = useRef({ initialValue: "opened" });
  const {
    isLoading: isLoadingBackofficeShipments,
    isRefetching: isRefetchingBackofficeShipments,
    data: backofficeShipments,
    refetch: getShipments,
  } = useQuery(`backoffice-shipments`, {
    queryFn: async () => {
      return service.getBackofficeShipmentsByStatus(filterRef.current);
    },
    enabled: false,
  });

  function getBackofficeShipments(currentStatus) {
    filterRef.current = currentStatus;
    getShipments();
  }

  const { mutate: createShipment, isLoading: isCreationLoading } = useMutation({
    mutationFn: async (values) => {
      const uploadablePackageSlips = ShipmentUtils.getUploadablePackageSlips(values);

      const uploadableAttachment = ShipmentUtils.getUploadableAttachment(values);

      try {
        const data = await service.createShipment(values);
        
        for (const packageSlip of uploadablePackageSlips) {
          await service.uploadPackageFile(data._id, packageSlip.file, packageSlip.itemId);
        }

        for (const attachment of uploadableAttachment) {
          await service.uploadAttachmentFile(data._id, attachment.file);
        }

        setSnackbarMessage({
          message: `New shipment created with Primary Reference: ${data.primaryReference}`,
          severity: "success",
        });

        navigate("/shipments");
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error creating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: createSpotmarketShipment, isLoading: isCreationSpotmarketLoading } = useMutation({
    mutationFn: async (values) => {
      const uploadablePackageSlips = ShipmentUtils.getUploadablePackageSlips(values);

      const uploadableAttachment = ShipmentUtils.getUploadableAttachment(values);

      try {
        const data = await service.createSpotmarketShipment(values);
        
        for (const packageSlip of uploadablePackageSlips) {
          await service.uploadPackageFile(data._id, packageSlip.file, packageSlip.itemId);
        }

        for (const attachment of uploadableAttachment) {
          await service.uploadAttachmentFile(data._id, attachment.file);
        }

        setSnackbarMessage({
          message: `New shipment created with Primary Reference: ${data.primaryReference}`,
          severity: "success",
        });

        navigate("/pending-shipments");
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error creating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: validateShipment, isLoading: isValidateLoading } =
    useMutation({
      mutationFn: ({ shipmentId }) =>
        service.validateShipment(shipmentId).then((data) => {
          if (data.errors || data.messages || data.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography>Error validated shipment</Typography>
                </Box>
              ),
              severity: "error",
            });

            return data;
          }
          setSnackbarMessage({
            message: `Shipment updated!`,
            severity: "success",
          });
          navigate("/shipments");
          return data;
        }),
    });

  const { mutate: consolidateShipment, isLoading: isConsolidateLoading } =
    useMutation({
      mutationFn: ({ shipmentId, callback }) =>
        service.consolidateShipment(shipmentId).then((data) => {
          if (data.errors || data.messages || data.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography>Error validated shipment</Typography>
                </Box>
              ),
              severity: "error",
            });

            return data;
          }
          setSnackbarMessage({
            message: `Shipment consolidated!`,
            severity: "success",
          });

          if (callback) callback(data);
        }),
  });

  const { mutate: updateSpotmarket, isLoading: isUpdateSpotLoading } = useMutation({
    mutationFn: async (values) => {      
      try {
        console.log(values)
        const data = await service.updateSpotmarket(values._id, values);        

        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message }
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({          
          severity: "success",
        });

        navigate(`/shipments/${data?._id}/edit/fill`);
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error updating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: updateShipment, isLoading: isUpdateLoading } = useMutation({
    mutationFn: async (values) => {
      const uploadablePackageSlips = ShipmentUtils.getUploadablePackageSlips(values);
      try {
        const data = await service.updateShipment(values._id, values);

        for (const packageSlip of uploadablePackageSlips) {
          await service.uploadPackageFile(data._id, packageSlip.file, packageSlip.itemId);
        }

        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message }
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment updated with Primary Reference: ${data.primaryReference}`,
          severity: "success",
        });

        navigate("/shipments");
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error updating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: updateSpotmarketShipment, isLoading: isUpdateSpotmarketLoading } = useMutation({
    mutationFn: (shipment) =>
      service.updateSpotmarketShipment(shipment._id, shipment).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message || "Error to update Shipment"}
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully updated!`,
          severity: "success",
        });
        navigate(`/shipments/${shipment._id}`);
        return data;
      }),
  });

  const { mutate: resetShipmentStatus, isLoading: isResettingShipmentLoading } =
    useMutation({
      mutationFn: ({ shipmentId }) =>
        service.resetShipmentStatus(shipmentId).then((data) => {
          if (data.errors || data.messages || data.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography>Error resetting status</Typography>
                </Box>
              ),
              severity: "error",
            });
            return data;
          }
          setSnackbarMessage({
            message: `Shipment status updated!`,
            severity: "success",
          });
          return data;
        }),
    });

  const { mutate: deleteShipment, isLoading: isDeletionLoading } = useMutation({
    mutationFn: (shipmentId) => service.deleteShipment(shipmentId),
    onSuccess: (data) => {
      if (data.errors) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography>Error deleting Shipment</Typography>
              <ErrorMessage response={data} />
            </Box>
          ),
          severity: "error",
        });
      } else {
        setSnackbarMessage({
          message: `Shipment successfully deleted!`,
          severity: "success",
        });
      }

      return data;
    },
  });

  const {
    isLoading: isLoadingCombinedShipments,
    data: combinedShipments,
    refetch: getCombinedShipments,
  } = useQuery("combined-shipments", {
    queryFn: async () => {
      return service.getCombinedShipments();
    },
    enabled: false,
  });

  const {
    data: combinedShipment,
    isLoading: isCombining,
    mutate: combineShipments,
    isSuccess: isCombined,
    isError: isCombineFailed,
  } = useMutation({
    mutationFn: async (combinedShipment) => {
      return service.createFromShipments(combinedShipment).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <ErrorMessage
                response={data}
                defaultMessage={"Error to Combine Shipment"}
              />
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully combined into ${data.primaryReference}!`,
          severity: "success",
        });
        // navigate(`/shipments/${shipment._id}`);
        return data;
      });
    },
    enabled: false,
  });

  const {
    data: uncombinedShipment,
    isLoading: isUncombining,
    mutate: uncombineShipments,
  } = useMutation({
    mutationFn: async ({ shipmentId }) => {
      return service.uncombine(shipmentId).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <ErrorMessage response={data} defaultMessage={"Error to Uncombine Shipment"} />
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully uncombined!`,
          severity: "success",
        });
        navigate('..');
        return data;
      });
    },
    enabled: false,
  });

  const {
    data: exportedDAT,
    isLoading: isLoadingExportDAT,
    mutate: exportDAT,
  } = useMutation({
    mutationFn: async ({ shipmentsIds, mode }) => {      
      return service.exportSpotmarketDatTs(shipmentsIds, mode).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <ErrorMessage
                response={data}
                defaultMessage={"Error to Export data"}
              />
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Your file is ready. Downloading...`,
          severity: "success",
        });
        
        const blob = new Blob([data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = mode == "TS" ? "TS.xlsx" : "DAT.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
        return data;
      });
    },
    enabled: false,
  });

  // HANDLE FILES
  const {
    isLoading: isUploadLoading,
    mutate: uploadFile,
    data: updatedShipment,
    isSuccess: isUploadSuccess,
  } = useMutation({
    mutationFn: ({ shipmentId, file, fileType }) => {
      return service
        .uploadShipmentFile(shipmentId, file, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: <Box> Success to upload {fileType} </Box>,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                {" "}
                Error to upload {fileType}: {error.message}
              </Box>
            ),
            severity: "error",
          });
        });
    },
  });

  let shipmentId = null;
  const { refetch: refetchShipment, isLoading: isShipmentLoading, data: shipment } = useQuery({
    queryFn: async () => service.getShipment(shipmentId),
    enabled: false
  });

  const getShipment = (id) => {
    shipmentId = id;
    refetchShipment(shipmentId);
  }

  // HANDLE PACKAGE SLIP FILES
  const {
    mutate: uploadPackageSlipFile,
    isSuccess: isUploadPackageSlipSuccess,
    isLoading: isUploadPackageSlipLoading,
    data: updatedPackageSlip,
  } = useMutation({
    mutationFn: async ({ shipmentId, file, itemId, onUploaded, onFailed }) => {
      return service
        .uploadPackageFile(shipmentId, file, itemId)
        .then(response => {
          onUploaded(response)
          return response;
        })
        .catch(error => {
          onFailed(error.message);
        });
    },
  });

  const {
    mutate: deletePackageSlipFile,
    isLoading: isDeletingPackageSlipFile,
    isSuccess: isFilePackageSlipDeleted,
    data: onFileDeletedPackageSlipData,
  } = useMutation({
    mutationFn: async ({ shipmentId, itemId, onDeleted, onFailed }) => {
      return await service.deletePackageFile(shipmentId, itemId)
        .then(response => {
          onDeleted(response)
        })
        .catch(error => {
          onFailed(error.message);
        });
    },
  });

  const {
    isLoading: isDeletingFile,
    mutate: deleteFile,
    isSuccess: isFileDeleted,
    data: onFileDeletedData,
  } = useMutation({
    mutationFn: ({ shipmentId, fileType }) => {
      return service
        .deleteShipmentFile(shipmentId, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: <Box> Success to delete {fileType} </Box>,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                {" "}
                Error to delete {fileType}: {error.message}
              </Box>
            ),
            severity: "error",
          });
        });
    },
  });

  const {
    isLoading: isFileImportLoading,
    mutate: importIntegrationFile,
    data: importIntegrationResponse,
    isSuccess: isIntegrationImportSuccess,
  } = useMutation({
    mutationFn: ({ shipmentId, loadId, fileType }) => {
      return service
        .importIntegrationFile(shipmentId, loadId, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: `${fileType} imported successfully`,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: error.message,
            severity: "error",
          });
        });
    },
  });

  // HANDLE ATTACHMENT FILES
  const {
    mutate: uploadAttachmentFile,
    isSuccess: isUploadAttachmentSuccess,
    isLoading: isUploadAttachmentLoading,
    data: updatedAttachment,
  } = useMutation({
    mutationFn: async ({ shipmentId, file, onUploaded, onFailed }) => {
      return service
        .uploadAttachmentFile(shipmentId, file)
        .then(response => {
          onUploaded(response)
          return response;
        })
        .catch(error => {
          onFailed(error.message);
        });
    },
  });

  const {
    mutate: deleteAttachmentFile,
    isLoading: isDeletingAttachmentFile,
    isSuccess: isFileAttachmentDeleted,
    data: onFileDeletedAttachmentData,
  } = useMutation({
    mutationFn: async ({ shipmentId, onDeleted, onFailed }) => {
      return await service.deleteAttachmentFile(shipmentId)
        .then(response => {
          onDeleted(response)
        })
        .catch(error => {
          onFailed(error.message);
        });
    },
  });

  // LOGISTIC ACTIONS
  const { 
    mutate: confirmShipment, 
    isLoading: isConfirmationLoading 
  } = useMutation({
      mutationFn: ({ shipmentId, quotationIndex, appointmentTimePickup, appointmentTimeStop, bypass, callbackConfirm, callbackAskForPending }) => {
        return service
          .confirmShipment(shipmentId, quotationIndex, appointmentTimePickup, appointmentTimeStop, bypass)
          .then((response) => {            
            if (response.errors || response.messages || response.message) {
              setSnackbarMessage({
                message: (
                  <Box>
                    <Typography mb={1} fontWeight={600}>
                      Error to Confirm quotation
                    </Typography>
                    <ErrorMessage response={response} />
                  </Box>
                ),
                severity: "error",
              });
            } 
            if (!response.askForPending) callbackConfirm(response);
            if (response.askForPending) callbackAskForPending(response.invalidOrders);
          });
      },
    });

  const {
    isLoading: isQuotationLoading,
    mutate: getCarrierQuotations,
    data: carrierQuotations,
  } = useMutation({
    mutationFn: ({ shipmentId, callbackSetQuotations, callbackAskForPending, bypass }) =>
      service.getCarrierQuotations(shipmentId, bypass).then((response) => {
        if (response.errors || response.messages || response.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1} fontWeight={600}>
                  Error to get Quotations
                </Typography>
                <ErrorMessage response={response} />
              </Box>
            ),
            severity: "error",
          });
        }
        if (response.length == 0) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>No Quotation Available</Typography>
              </Box>
            ),
            severity: "warning",
          });
        }
        if (response.length && response.length > 0 && !response.askForPending) 
          callbackSetQuotations(response);
        if (response.askForPending) callbackAskForPending(response.invalidOrders);
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  const {
    isLoading: isShipmentQuotationLoading,
    mutate: getShipmentQuotations,
    data: shipmentQuotations,
  } = useMutation({
    mutationFn: ({ shipmentId, callback, bypass }) =>
      service.getShipmentQuotations(shipmentId, bypass).then((response) => {
        if (response.errors || response.messages || response.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1} fontWeight={600}>
                  Error to get Quotations
                </Typography>
                <ErrorMessage response={response} />
              </Box>
            ),
            severity: "error",
          });
        }
        if (response.length == 0) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>No Quotation Available</Typography>
              </Box>
            ),
            severity: "warning",
          });
        }
        if (response.length && response.length > 0) callback(response);
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  const {
    isLoading: isHistoryLoading,
    mutate: getHistoryById,
    data: shipmentHistory,
  } = useMutation({
    mutationFn: ({ shipmentId }) =>
      service.getHistoryById(shipmentId).then((response) => {
        if (response.errors || response.messages || response.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1} fontWeight={600}>
                  Error to get History
                </Typography>
                <ErrorMessage response={response} />
              </Box>
            ),
            severity: "error",
          });
        }
        if (response.length == 0) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>No History Available</Typography>
              </Box>
            ),
            severity: "warning",
          });
        }        
        if (response.length && response.length > 0) return response;
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  const {
    isLoading: isLoadingClosedBackofficeShipments,
    isRefetching: isRefetchingClosedBackofficeShipments,
    data: closedBackofficeShipments,
    refetch: getClosedBackofficeShipments,
  } = useQuery(`closed-backoffice-shipments`, {
    queryFn: async () => {
      return service.getBackofficeShipmentsByStatus("closed");
    },
    enabled: false,
  });

  const {
    isLoading: isLoadingPendingShipments,
    isRefetching: isRefetchingPendingShipments,
    data: pendingShipments,
    refetch: getPendingShipments,
  } = useQuery(`pending-shipments`, {
    queryFn: async () => {
      return service.getShipments("pending");
    },
    enabled: false
  });

  const { mutate: approveAllShipments } = useMutation(
    () => {
      return service.approveAllShipments();
    },
    {
      onSuccess: (data) => {
        setSnackbarMessage({
          message: `Shipments approved successfully!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error approving shipments: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  const { mutate: denyAllShipments } = useMutation(
    () => {
      return service.denyAllShipments();
    },
    {
      onSuccess: (data) => {
        setSnackbarMessage({
          message: `Shipments denied successfully!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error denying shipments: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  const { 
    mutate: pendingShipment,
    isLoading: isPendingLoading,
  } = useMutation(
    ({ shipmentId, rate, callback }) => {
      return service.pendingShipment(shipmentId, rate);
    },
    {
      onSuccess: (data, variables) => {
        const { callback } = variables;
        if (callback) callback();
        setSnackbarMessage({
          message: `Shipment's pending!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error pending shipment: ${error.message}`,
          severity: "error",
        });
      },
    }
  );
 

  const { mutate: setShipmentAsPendingAndWaiting } = useMutation(
    ({ shipmentId }) => {
      return service.pendingShipment(shipmentId, null, true);
    }, {
      onSuccess: (data, variables) => {
        const { callback } = variables;
        if (callback) callback();
        setSnackbarMessage({
          message: `Shipment's pending!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error pending shipment: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  const { 
    mutate: approveShipment,
    isLoading: isApprovingShipmentLoading,
  } = useMutation(
    ({ shipmentId }) => {
      return service.approveShipment(shipmentId);
    },
    {
      onSuccess: (data, variables) => {
        const { callback } = variables;
        if (callback) callback();
        setSnackbarMessage({
          message: `Shipment approved successfully!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error approving shipment: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  const { 
    mutate: denyShipment,
    isLoading: isDenyingShipmentLoading,
  } = useMutation(
    ({ shipmentId }) => {
      return service.denyShipment(shipmentId);
    },
    {
      onSuccess: (data, variables) => {
        const { callback } = variables;
        if (callback) callback();
        setSnackbarMessage({
          message: `Shipment archived successfully!`,
          severity: "success",
        });
      },
      onError: (error) => {
        setSnackbarMessage({
          message: `Error archiving shipment: ${error.message}`,
          severity: "error",
        });
      },
    }
  );

  const onNewPendingShipment = (action) => {
    if (!socket) return;
    socket.on('new-pending', action);
    return () => socket.off('new-pending', action);
  }

  const onApproveDenyShipment = (action) => {
    if (!socket) return;
    socket.on('new-approved-denied', action);
    return () => socket.off('new-approved-denied', action);
  }

  const { mutate: getOrderDetails, data: orders, isLoading: isOrdersLoading } = useMutation(
    (shipmentId) => service.getOrderDetails(shipmentId)
  );

  const { mutate: candidateCarrier } =
  useMutation({
    mutationFn: ({ rate, mc, eta, emptyOn, shipmentId }) => {
      return carrierService
        .candidateCarrier(rate, mc, eta, emptyOn, shipmentId)
        .then((response) => {
          if (response.errors || response.messages || response.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography mb={1} fontWeight={600}>
                    Error to Add Candidate Carrier
                  </Typography>
                  <ErrorMessage response={response} />
                </Box>
              ),
              severity: "error",
            });
          }

          return response;
        });
    },
  });

  const { mutate: confirmCarrier } =
  useMutation({
    mutationFn: ({ carrierId, shipmentId }) => {
      return carrierService
        .confirmCarrier(carrierId, shipmentId)
        .then((response) => {
          if (response.errors || response.messages || response.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography mb={1} fontWeight={600}>
                    Error to Add Candidate Carrier
                  </Typography>
                  <ErrorMessage response={response} />
                </Box>
              ),
              severity: "error",
            });
          }

          return response;
        });
    },
  });

  
  const {
    isLoading: isCandidateLoading,
    mutate: getCandidateCarrier,
    data: candidateCarriers,
  } = useMutation({
    mutationFn: ({ shipmentId }) =>
      carrierService.getCandidatesCarriers(shipmentId).then((response) => {        
        return response;
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  return {
    isLoadingBackofficeShipments,
    isRefetchingBackofficeShipments,
    backofficeShipments,
    getBackofficeShipments,

    isLoadingCombinedShipments,
    combinedShipments,
    getCombinedShipments,

    combineShipments,
    isCombining,
    isCombined,
    isCombineFailed,
    combinedShipment,

    createShipment,
    isCreationLoading,

    createSpotmarketShipment,
    isCreationSpotmarketLoading,
    updateSpotmarketShipment,
    isUpdateSpotmarketLoading,

    resetShipmentStatus,
    isResettingShipmentLoading,

    updateShipment,
    isUpdateLoading,

    isUploadLoading,
    uploadFile,
    isUploadSuccess,
    updatedShipment,

    isDeletingFile,
    deleteFile,
    isFileDeleted,
    onFileDeletedData,

    isFileImportLoading,
    importIntegrationFile,
    importIntegrationResponse,
    isIntegrationImportSuccess,

    deleteShipment,
    isDeletionLoading,

    confirmShipment,
    isConfirmationLoading,

    isQuotationLoading,
    getCarrierQuotations,
    carrierQuotations,

    isHistoryLoading,
    getHistoryById,
    shipmentHistory,

    isLoadingClosedBackofficeShipments,
    isRefetchingClosedBackofficeShipments,
    closedBackofficeShipments,
    getClosedBackofficeShipments,

    pendingShipment,
    isPendingLoading,
    isApprovingShipmentLoading,
    approveShipment,
    approveAllShipments,
    isDenyingShipmentLoading,
    denyShipment,
    denyAllShipments,
    isLoadingPendingShipments,
    isRefetchingPendingShipments,
    pendingShipments,
    setShipmentAsPendingAndWaiting,
    getPendingShipments,
    onNewPendingShipment,
    onApproveDenyShipment,

    uncombinedShipment,
    isUncombining,
    uncombineShipments,

    validateShipment,
    isValidateLoading,

    consolidateShipment,
    isConsolidateLoading,

    isUploadPackageSlipLoading,
    uploadPackageSlipFile,
    updatedPackageSlip,
    isUploadPackageSlipSuccess,

    isDeletingPackageSlipFile,
    deletePackageSlipFile,
    isFilePackageSlipDeleted,
    onFileDeletedPackageSlipData,

    getShipment,
    isShipmentLoading,
    shipment,

    uploadAttachmentFile,
    isUploadAttachmentSuccess,
    isUploadAttachmentLoading,
    updatedAttachment,

    deleteAttachmentFile,
    isDeletingAttachmentFile,
    isFileAttachmentDeleted,
    onFileDeletedAttachmentData,

    getOrderDetails,
    isOrdersLoading,
    orders,

    candidateCarrier, 
    isCandidateLoading,    
    getCandidateCarrier,
    candidateCarriers,
    confirmCarrier,

    isShipmentQuotationLoading,
    getShipmentQuotations,
    shipmentQuotations,

    exportedDAT,
    isLoadingExportDAT,
    exportDAT,

    updateSpotmarket,
    isUpdateSpotLoading
  };
}
