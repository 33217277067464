export class ShipmentLocationContact {
    name = null;
    email = null;
    phone = null;

    static fromPickup(pickup) {
        const contact = new ShipmentLocationContact();
        contact.name = pickup.originContact;
        contact.email = pickup.originEmail;
        contact.phone = pickup.originPhone;
        return contact;
    }

    static fromStop(stop) {
        const contact = new ShipmentLocationContact();
        contact.name = stop.destContact;
        contact.email = stop.destEmail;
        contact.phone = stop.destPhone;
        return contact;
    }
}

export class ShipmentLocation {
    name = null;
    address1 = null;
    address2 = null;
    city = null;
    state = null;
    zip = null;
    country = null;
    targetEarly = null;
    targetLate = null;
    estArrivalTime = null;
    actualArrival = null;
    timezone = null;
    contacts = [];
    loadingDock = null;
    forklift = null;
    packageSlip = null;
    needsAppointment = null;
    coordinate = null;

    static toStop(location) {
        const poc = location.contacts[0];
        
        return {
            type: location.type,
            destName: location.name,
            destAddr1: location.address1,
            destAddr2: location.address2,
            destCity: location.city,
            destState: location.state,
            destZip: location.zip,
            destCtry: location.country,
            targetDeliveryEarly: location.targetEarly,
            targetDeliveryLate: location.targetLate,
            estArrivalTime: location.estArrivalTime,
            actualDelivery: location.actualArrival,
            destContact: poc?.name ?? '',
            destEmail: poc?.email ?? '',
            destPhone: poc?.phone ?? '',
            timezone: location.timezone,
            loadingDock: location.loadingDock,
            forklift: location.forklift,
            packageSlip: location.packageSlip,
            needsAppointmentStop: location.needsAppointment,
            coordinate: location.coordinate,
        }
    }

    static toPickup(location) {
        const poc = location.contacts[0];
        
        return {
            type: location.type,
            originName: location.name,
            originAddr1: location.address1,
            originAddr2: location.address2,
            originCity: location.city,
            originState: location.state,
            originZip: location.zip,
            originCtry: location.country,
            targetShipEarly: location.targetEarly,
            targetShipLate: location.targetLate,
            estArrivalTime: location.estArrivalTime,
            actualShip: location.actualArrival,
            originContact: poc?.name ?? '',
            originEmail: poc?.email ?? '',
            originPhone: poc?.phone ?? '',
            timezone: location.timezone,
            loadingDock: location.loadingDock,
            forklift: location.forklift,
            packageSlip: location.packageSlip,
            needsAppointmentPickup: location.needsAppointment,
            coordinate: location.coordinate,
        }
    }

    static fromStop(stop) {
        const location = new ShipmentLocation();
        location.name = stop.destName;
        location.address1 = stop.destAddr1;
        location.address2 = stop.destAddr2;
        location.city = stop.destCity;
        location.state = stop.destState;
        location.zip = stop.destZip;
        location.country = stop.destCtry;
        location.targetEarly = stop.targetDeliveryEarly;
        location.targetLate = stop.targetDeliveryLate;
        location.estArrivalTime = stop.estArrivalTime;
        location.actualArrival = stop.actualDelivery;
        location.timezone = stop.timezone;
        location.contacts = [ShipmentLocationContact.fromStop(stop)];
        location.forklift = stop.forklift;
        location.packageSlip = stop.packageSlip;
        location.loadingDock = stop.loadingDock;
        location.needsAppointment = stop.needsAppointmentStop;
        location.coordinate = stop.coordinate;
        return location;
    }

    static fromPickup(pickup) {
        const location = new ShipmentLocation();
        location.name = pickup.originName;
        location.address1 = pickup.originAddr1;
        location.address2 = pickup.originAddr2;
        location.city = pickup.originCity;
        location.state = pickup.originState;
        location.zip = pickup.originZip;
        location.country = pickup.originCtry;
        location.targetEarly = pickup.targetShipEarly;
        location.targetLate = pickup.targetShipLate;
        location.estArrivalTime = pickup.estArrivalTime;
        location.actualArrival = pickup.actualShip;
        location.timezone = pickup.timezone;
        location.contacts = [ShipmentLocationContact.fromPickup(pickup)];
        location.forklift = pickup.forklift;
        location.packageSlip = pickup.packageSlip;
        location.loadingDock = pickup.loadingDock;
        location.needsAppointment = pickup.needsAppointmentPickup;
        location.coordinate = pickup.coordinate;
        return location;
    }
}