import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { useCityQueries } from "./hooks/useCityQueries";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useEffect } from "react";
import { CityForm } from "./form/CityForm";

export function UpdateCityPage() {
  const { cityId } = useParams();

  const { 
    getCity, 
    isCityLoading, 
    city, 
    update, 
    isUpdating 
  } = useCityQueries();

  const { setSnackbarMessage } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (cityId) getCity(cityId);
    else navigate("/cities");    
  }, [cityId]);

  const onSave = (city) =>
    update({
      city,
      onCreated: (data) => {
        if (data.errors)
          setSnackbarMessage({
            message: <Box> Error submitting city.</Box>,
            severity: "error",
          });
        else {
          setSnackbarMessage({
            message: `${data.name} successfully updated`,
            severity: "success",
          });
          navigate(`/cities/${data._id}`);
        }
      },
      onFailed: (err) =>
        setSnackbarMessage({
          message: <Box> {`${err.message}`}</Box>,
          severity: "error",
        }),
    });

  return (
    <Paper
      sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3 }}
      elevation={3}
    >
      <Helmet>
        <title>Update City</title>
      </Helmet>
      <Box sx={{ maxWidth: "80%", margin: "auto" }}>
        <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
          Update City
        </Typography>
        <CityForm
          onSave={onSave}
          useFormParams={{ defaultValues: city }}
          isSubimissionLoading={isUpdating}
        />
      </Box>
    </Paper>
  );
}
