export const mobileSetup = {
  "pinnedColumns": {
      "left": [
          "poNumber",
          "primaryReference"
      ],
      "right": ["actions"]
  },
  "columns": {
      "columnVisibilityModel": {
          "poNumber": false,
          "metadata.spotmarket.postedRate": false,
          "metadata.spotmarket.truckRateRange": false,
          "primaryReference": true,
          "mode": true,
          "status": true,
          "created.at": false,
          "metadata.rate": false,
          "integrated": false,
          "quantity": false,
          "weight": false,
          "shipper": false,
          "originCity": false,
          "originLocation": false,
          "pickups[0].targetShipLate": false,
          "receiver": false,
          "destinationCity": false,
          "destinationLocation": false,
          "stops[0].targetDeliveryLate": false,
          "trackingUrl": false,
          "bolFile.url": false,
          "podFile.url": false
      },
      "orderedFields": [
          "poNumber",
          "primaryReference",
          "mode",
          "status",
          "created.at",
          "metadata.rate",
          "integrated",
          "quantity",
          "weight",
          "shipper",
          "originCity",
          "originLocation",
          "pickups[0].targetShipLate",
          "receiver",
          "destinationCity",
          "destinationLocation",
          "stops[0].targetDeliveryLate",
          "trackingUrl",
          "bolFile.url",
          "podFile.url"
      ],
      "dimensions": {
          "poNumber": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 137,
              "flex": 0
          },
          "primaryReference": {
              "maxWidth": -1,
              "minWidth": 120,
              "width": 100
          },
          "mode": {
              "maxWidth": -1,
              "minWidth": 80,
              "width": 100
          },
          "status": {
              "maxWidth": -1,
              "minWidth": 120,
              "width": 100
          },
          "created.at": {
              "maxWidth": -1,
              "minWidth": 150,
              "width": 100
          },
          "integrated": {
              "maxWidth": -1,
              "minWidth": 80,
              "width": 100
          },
          "quantity": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 100
          },
          "weight": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 100
          },
          "shipper": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "originCity": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "originLocation": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "pickups[0].targetShipLate": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "receiver": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "destinationCity": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "destinationLocation": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "stops[0].targetDeliveryLate": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "trackingUrl": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "bolFile.url": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "podFile.url": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          }
      }
  },
  "preferencePanel": {
      "open": false
  },
  "sorting": {
      "sortModel": [
          {
              "field": "created.at",
              "sort": "desc"
          }
      ]
  },
  "pagination": {
      "paginationModel": {
          "page": 0,
          "pageSize": 15
      }
  }
}

export const desktopSetup = {
  "pinnedColumns": {
      "left": [
          "poNumber",
          "primaryReference"
      ],
      "right": []
  },
  "columns": {
      "columnVisibilityModel": {},
      "orderedFields": [
          "poNumber",
          "primaryReference",
          "spotmarket.postedRate",
          "spotmarket.truckRateRange",
          "mode",
          "status",
          "created.at",
          "metadata.rate",
          "integrated",
          "quantity",
          "weight",
          "shipper",
          "originCity",
          "originLocation",
          "pickups[0].targetShipLate",
          "receiver",
          "destinationCity",
          "destinationLocation",
          "stops[0].targetDeliveryLate",
          "trackingUrl",
          "bolFile.url",
          "podFile.url"
      ],
      "dimensions": {
          "poNumber": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 137,
              "flex": 0
          },
          "primaryReference": {
              "maxWidth": -1,
              "minWidth": 120,
              "width": 100
          },
          "mode": {
              "maxWidth": -1,
              "minWidth": 80,
              "width": 100
          },
          "status": {
              "maxWidth": -1,
              "minWidth": 120,
              "width": 100
          },
          "created.at": {
              "maxWidth": -1,
              "minWidth": 150,
              "width": 100
          },
          "metadata.rate": {
              "maxWidth": -1,
              "minWidth": 150,
              "width": 100
          },
          "integrated": {
              "maxWidth": -1,
              "minWidth": 80,
              "width": 100
          },
          "quantity": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 100
          },
          "weight": {
              "maxWidth": -1,
              "minWidth": 100,
              "width": 100
          },
          "shipper": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "originCity": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "originLocation": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "pickups[0].targetShipLate": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "receiver": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "destinationCity": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "destinationLocation": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "stops[0].targetDeliveryLate": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "trackingUrl": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "bolFile.url": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          },
          "podFile.url": {
              "maxWidth": -1,
              "minWidth": 200,
              "width": 100
          }
      }
  },
  "filter": {
      "filterModel": {
          "items": [],
          "logicOperator": "and",
          "quickFilterValues": [],
          "quickFilterLogicOperator": "and"
      }
  },
  "sorting": {
      "sortModel": [
          {
              "field": "created.at",
              "sort": "desc"
          }
      ]
  },
  "pagination": {
      "paginationModel": {
          "page": 0,
          "pageSize": 15
      }
  }
}