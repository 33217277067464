import { createContext } from "react";
import { useShipmentFormHook } from "../hooks/useShipmentFormHook";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";

export const ShipmentFormContext = createContext(null);

export function ShipmentFormProvider({ children, ...props }) {
  const useFormParams = props.useFormParams;
  const onSave = props.onSave;
  const reset = props.reset || false;
  const onError = props.onError;

  const {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,
    mapFields,
    addOrder,
    removeOrder,
    getOrderByPONumber,
    items,
    poNumbers,
    ordersNumbers,
    ...rest
  } = useShipmentFormHook({ useFormParams, onSave, reset, onError })

  return (
    <ShipmentFormContext.Provider
      value={{
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,
        mapFields,
        addOrder,
        removeOrder,
        getOrderByPONumber,
        items,
        poNumbers,
        ordersNumbers,
        ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {
            console.log('1')
            clearErrors();            
            handleSubmit(onSubmitHandler)(event);
          }}
          sx={props.sx}
        >
          { children }
        </Box>
      </FormProvider>
    </ShipmentFormContext.Provider>
  );
}