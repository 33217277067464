import { CloseOutlined } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { CombineForm } from "./form/CombineForm";
import { ShipmentCombineFormProvider } from "../context/ShipmentCombinedFormContext";
import { LoadingButton } from "@mui/lab";
import { ShipmentUtils } from "src/utils";

export function CombineShipmentsModal({
  open,
  handleClose,
  shipments,
}) {

  const { combineShipments, isCombining, isCombined } = useShipmentQueries();

  useEffect(() => {
    if (isCombined) onClose();
  }, [isCombined]);

  const shipment = useMemo(() => {
    return ShipmentUtils.combine(shipments);
  }, [shipments]);

  const onClose = () => handleClose();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        mt: '16px',
        mx: {
          xs: "0",
          sm: "0",
          md: "120px",
          lg: "120px",
          xl: "'120px'",
        },
      }}
      PaperProps={{ sx: { borderRadius: '16px 16px 0px 0px' } }}
    >
      <AppBar sx={{ position: 'relative', py: '0.7rem' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
            <Typography
              variant="h1"
              sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" }, py: 2 }}
            >
              Shipments to Combine
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <ShipmentCombineFormProvider
          onSave={combineShipments}
          combinedShipment={shipment}
        >
          <CombineForm />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              py: 2,
              gap: 2,
            }}
          >
            <LoadingButton loading={isCombining} type="submit" variant="contained" sx={{ flex: 1 }}>
              Combine
            </LoadingButton>
          </Box>
        </ShipmentCombineFormProvider>
      </DialogContent>
    </Dialog>
  );
}