import {
  z
} from "zod";

export class Service {
  static get createSchema() {
    return z.object({
      excessLength12ftAndOver: z.boolean(),
      excessLength12to15ft: z.boolean(),
      excessLength8to11ft: z.boolean(),
      airportPickup: z.boolean(),
      guaranteedDelivery: z.boolean(),
      hazardousCargoCharge: z.boolean(),
      insideDelivery: z.boolean(),
      liftGateDelivery: z.boolean(),
      liftGatePickup: z.boolean(),
      limitedAccess: z.boolean(),
      notifyBeforeDelivery: z.boolean(),
      protectFromFreezing: z.boolean(),
      residentialDelivery: z.boolean(),
      residentialPickup: z.boolean(),
      scheduleApptCharge: z.boolean(),
      loadingDockPickup: z.boolean(),
      loadingDockStop: z.boolean(),
      forkliftPickup: z.boolean(),
      forkliftStop: z.boolean(),
    });
  }

  static fromLocation(location, type) {
    if (type === 'pickup') {
      return {
        needsAppointmentPickup: location.needsAppointment,
        loadingDockPickup: location.loadingDock,
        forkliftPickup: location.forklift,
      };
    } else {
      return {
        needsAppointmentStop: location.needsAppointment,
        loadingDockStop: location.loadingDock,
        forkliftStop: location.forklift,
      }
    }
  }

  static fromPickup(pickup) {
    return {
      needsAppointmentPickup: pickup.needsAppointmentPickup,
      loadingDockPickup: pickup.loadingDock,
      forkliftPickup: pickup.forklift,
    };
  }

  static fromStop(stop) {
    return {
      needsAppointmentStop: stop.needsAppointmentStop,
      loadingDockStop: stop.loadingDock,
      forkliftStop: stop.forklift,
    }
  }
}