import { useTenant } from "src/hooks/useTenant";

import { UpdateRegularShipmentPage } from "./UpdateRegularShipmentPage";
import { UpdateSpotmarketShipmentPage } from "./UpdateSpotmarketShipmentPage";

export function UpdateShipmentPage(props) {
  const { tenant } = useTenant();
  
  const renderPage = () => {
    
    if (tenant.modules?.spotmarket && !props?.confirm) 
      return <UpdateSpotmarketShipmentPage />

    return <UpdateRegularShipmentPage />;
  }

  return (
    <> { renderPage() } </>
  )

}
