import { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { Box, Button, Paper, Typography } from "@mui/material";
import { UsersDetails } from "../components/details/UsersDetails";
import { transformToMongoQuery } from "../constants/transformMongoQuery";
import { usersFields } from "../constants/fieldsMongo";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import { useAuditsQueries } from "../hooks/useAuditsQueries";

const initialQuery = {
  combinator: "and",
  rules: [
    { field: usersFields[1].name, operator: '=', value: '' },
  ],
};

export const UsersAudits = () => {
  const [query, setQuery] = useState(initialQuery);
  const [isOpen, setOpen] = useState();
  const [mongoQuery, setMongoQuery] = useState()

  const {
    get,
    isAuditsLoading,
    audits
  } = useAuditsQueries();

  useEffect(() => {
    if (mongoQuery) get(mongoQuery)
  }, [mongoQuery])

  const search = () => {
    setMongoQuery(transformToMongoQuery(query));
    setOpen(true)
  }

  const clear = () => {
    setMongoQuery(transformToMongoQuery());
    setQuery(initialQuery);
    setOpen(false)
  }

  return (
    <Paper
      sx={{ maxWidth: { lg: "1200px", xs: "90%" }, margin: "auto", mt: '2rem', py: 3 }}
      elevation={3}
    >
      <Box sx={{ maxWidth: "80%", margin: "auto" }}>
        <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
          Users
        </Typography>
        <QueryBuilderMaterial>
          <QueryBuilder fields={usersFields} query={query} onQueryChange={setQuery} controlClassnames={{ queryBuilder: 'queryBuilder-branches' }} showCloneButtons />
        </QueryBuilderMaterial>
        <Box
          display={"flex"}
          justifyContent={'space-between'}
          sx={{ mb: "20px" }}
        >
          <Button
            onClick={() => search()}
            variant="contained"
            sx={{
              py: "0.8rem",
              marginTop: "10px",
              padding: "10px",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clear()}
            variant="outlined"
            size="small"
            sx={{
              py: "0.8rem",
              lineHeight: 1.5,
              textTransform: "capitalize",
              marginTop: "10px",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Clear
          </Button>
        </Box>
        {isOpen && <UsersDetails audits={audits} isLoading={isAuditsLoading} />}
      </Box>
    </Paper>
  );
};
