import React from "react";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";

import BackofficeShipmentsPage from "./BackofficeShipmentsPage";
import RegularShipmentsPage from "./RegularShipmentsPage";
import SpotmarketShipmentsPage from "./SpotmarketShipmentsPage";

export default function ShipmentsPage() {
  const { session } = useAuth();
  const { tenant } = useTenant();
  
  const auth = session?.permissions;
  const renderPage = () => {
    
    if (tenant.modules?.spotmarket) 
      return <SpotmarketShipmentsPage />

    if (auth?.IsBackOffice || auth?.IsLTLManager) 
      return <BackofficeShipmentsPage />

    return <RegularShipmentsPage />;
  }

  return (
    <> { renderPage() } </>
  )
}
