import { Typography, Stack, MenuItem, FormControl, Select, OutlinedInput, InputLabel } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useCityQueries } from "src/pages/city/hooks/useCityQueries";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { usaStates } from "src/pages/warehouses/constants/usaStates";
import { useFormContext } from "react-hook-form";

export function StopCitiesFields({ itemIndex = 0, ...props }) {
  const {
    alternativeCities,
    getCitiesFromLocation
  } = useCityQueries();

  const { setValue } = useFormContext();  

useEffect(() => {
  const validateFields = debounce(() => {
    const city = props?.location?.destCity;
    const state = props?.location?.destState;
    const country = props?.location?.destCtry;

    if (city && state && country) {
      getCitiesFromLocation({
        city,
        state,
        ctry: country,
      })
    }
  });

  validateFields();
  return () => validateFields.cancel();
}, [
  props?.location
]);

const getStates = () => {
  const country = props?.location?.destCtry;
  const destState = props?.location?.destState;

  if (!destState) return null

  const findState = (states) =>
    states.find((state) => state.acronym === destState)?.name || null

  if (country === 'CA') {
    return findState(canadaStates)
  } else if (country === 'MX') {
    return findState(mexicoStates)
  } else {
    return findState(usaStates)
  }
}

const getAcronym = (acronym) => {
  if(!acronym) return null

  const findAcronym = (states) => 
    states.find((state) => state.name === acronym)?.acronym || null
  
  if(findAcronym(canadaStates)) return findAcronym(canadaStates)
  else if(findAcronym(mexicoStates)) return findAcronym(mexicoStates)
  else return findAcronym(usaStates)
}

const [states, setStates] = useState(getStates());

const [ fullAddress ] = useState(`${props?.location?.destCity}, ${states}, ${props?.location?.destCtry}`)

useEffect(() => {
  setStates(getStates())
}, [props?.location?.destCtry])

const [ allCities, setAllCities ] = useState([])

useEffect(() => {
  let cities = [];    
  if (Array.isArray(alternativeCities)) {      
    cities = alternativeCities;
    const addAddres = {
      city: props?.location?.destCity,
      state: states,
      ctry: props?.location?.destCtry
    }
    if (!cities?.some(address => address.city == props?.location?.destCity)) cities.push(addAddres)
    setAllCities(cities)
  }
}, [alternativeCities])

const handleLocation = (e) => {
  const address = e.target.value
  const [ city, state, country ] = address.split(',').map(item => item.trim())  
  setValue('stops.0.destCity', city)
  setValue('stops.0.destState', getAcronym(state))
  setValue('stops.0.destCtry', country)       
}

return (
  <Stack spacing={1} width={"100%"}>
    {!props.confirm &&
      <>
        <FormControl>
          <InputLabel id="alternative-cities">Alternative Cities</InputLabel>
          <Select
            defaultValue={fullAddress}
            label="Alternative Cities"
            select              
            required
            width={"100%"}
            onChange={handleLocation}
          >            
              {(Array.isArray(allCities) && allCities.length > 0 ) ? (allCities?.map((city) => {
                const cityAddress = `${city.city}, ${states}, ${city.ctry}`;
                return (
                  <MenuItem key={cityAddress} value={cityAddress}>
                    {city.city}, {states}, {city.ctry}
                  </MenuItem>
                );
              })) : (
                <MenuItem key="" value={fullAddress}>
                  {fullAddress}
                </MenuItem>
              )}
          </Select>
        </FormControl>
      </>}
  </Stack>
);
}
