import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { SpotmarketForm } from "./components/form/SpotmarketForm";
import { useShipmentQueries } from "./hooks/useShipmentQueries";
import { SavingShipmentDialog } from "./components/SavingShipmentDialog";

export function CreateSpotmarketShipmentPage() {
  const { createSpotmarketShipment, isCreationSpotmarketLoading } = useShipmentQueries();

  return (
    <>
      <Paper
        sx={{
          maxWidth: {
            lg: "1000px",
            xs: "90%",
          },
          margin: "auto",
          py: 3,
        }}
        elevation={3}
      >
        <Helmet>
          <title>Create a New Spotmarket Shipment</title>
        </Helmet>
        <SpotmarketForm
          onSave={createSpotmarketShipment}
          isSubmissionLoading={isCreationSpotmarketLoading}
          initialized={false}
        />
        <SavingShipmentDialog isOpen={isCreationSpotmarketLoading} />
      </Paper>

    </>
  );
}
