import { Stack, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useCityQueries } from "src/pages/city/hooks/useCityQueries";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { usaStates } from "src/pages/warehouses/constants/usaStates";
import { useFormContext } from "react-hook-form";

export function PickupCitiesFields({ itemIndex = 0, ...props }) {
  const {
    alternativeCities,
    getCitiesFromLocation
  } = useCityQueries();

  const { setValue } = useFormContext();

  useEffect(() => {
    const validateFields = debounce(() => {
      const city = props?.location?.originCity;
      const state = props?.location?.originState;
      const country = props?.location?.originCtry;

      if (city && state && country) {
        getCitiesFromLocation({
          city,
          state,
          ctry: country,
        })
      }
    });

    validateFields();
    return () => validateFields.cancel();
  }, [
    props?.location
  ]);

  const getStates = () => {
    const country = props?.location?.originCtry;
    const originState = props?.location?.originState;

    if (!originState) return null

    const findState = (states) =>
      states.find((state) => state.acronym === originState)?.name || null

    if (country === 'CA') {
      return findState(canadaStates)
    } else if (country === 'MX') {
      return findState(mexicoStates)
    } else {
      return findState(usaStates)
    }
  }

  const getAcronym = (acronym) => {
    if (!acronym) return null

    const findAcronym = (states) =>
      states.find((state) => state.name === acronym)?.acronym || null

    if (findAcronym(canadaStates)) return findAcronym(canadaStates)
    else if (findAcronym(mexicoStates)) return findAcronym(mexicoStates)
    else return findAcronym(usaStates)
  }

  const [states, setStates] = useState(getStates());

  const [fullAddress] = useState(`${props?.location?.originCity}, ${states}, ${props?.location?.originCtry}`)

  useEffect(() => {
    setStates(getStates())
  }, [props?.location?.destCtry])

  const [allCities, setAllCities] = useState([])

  useEffect(() => {
    let cities = [];
    if (Array.isArray(alternativeCities)) {
      cities = alternativeCities;
      const addAddres = {
        city: props?.location?.originCity,
        state: states,
        ctry: props?.location?.originCtry
      }
      if (!cities?.some(address => address.city == props?.location?.originCity)) cities.push(addAddres)
      setAllCities(cities)
    }
  }, [alternativeCities])

  useEffect(() => {

  }, [fullAddress])

  const handleLocation = (e) => {
    const address = e.target.value
    const [city, state, country] = address.split(',').map(item => item.trim())
    setValue('pickups.0.originCity', city)
    setValue('pickups.0.originState', getAcronym(state))
    setValue('pickups.0.originCtry', country)
  }


  return (
    <Stack spacing={1} width={"100%"}>
      {!props.confirm &&
        <>
          <FormControl>
            <InputLabel id="alternative-cities">Alternative Cities</InputLabel>
            <Select
              defaultValue={fullAddress}
              label="Alternative Cities"
              select
              required
              width={"100%"}
              onChange={handleLocation}
            >
              {(Array.isArray(allCities) && allCities.length > 0 ) ? (allCities?.map((city) => {
                const cityAddress = `${city.city}, ${states}, ${city.ctry}`;
                return (
                  <MenuItem key={cityAddress} value={cityAddress}>
                    {city.city}, {states}, {city.ctry}
                  </MenuItem>
                );
              })) : (
                <MenuItem key={fullAddress} value={fullAddress}>
                  {fullAddress}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>}
    </Stack>
  );
}
