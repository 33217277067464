import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { OrderService } from "src/api/orders.service";
import { PONumberField } from "./PONumberField/PONumberField";
import { useShipmentFormContext } from "../../hooks/useForm";
import { PackagePOMapper } from "./PackageFields/PackagePOMapper";

export const SpotmarketPORequiredPackageForm = ({ onDone, isOpen, onClose }) => {
    const InitialState = {
        isPalletized: null,
        isMoreThan5Pallets: null,
    };
    const [state, setState] = useState(InitialState);
    const { setValue, getValues } = useFormContext();
    const [params] = useSearchParams();
    // const { packageIndex } = usePackageForm();
    const { form } = useShipmentFormContext();  
    // const {
    //     fields,
    //     append,
    //     remove: removeItem,
    //   } = useFieldArray({
    //     control: form.control,
    //     name: `items.${packageIndex}.subItems`,
    //   }); 

    const {
        form: { watch },
        addOrder,
    } = useShipmentFormContext();

    const { session } = useAuth();
    const isWarehouseManager = session.permissions.IsWarehouseManager;
    const service = new OrderService(session.token, session.tenant._id);
    const orders = params.get("orders");

    useEffect(() => {
        const execute = async () => {
            if (orders) {
                const result = await service.getOrdersById(orders);
                if (result) {
                    result.forEach((data) => {
                        addOrder(data);
                        const poNumber = `${data.PO_NUM}-${data.RELEASE_NUM ?? 0}`;
                        const poNumbers = getValues('poNumber')
                        poNumbers.push(poNumber);
                        setValue("poNumber", poNumbers)
                    })
                }
            }
        };
        execute();
    }, [orders]);

    const poNumbers = useMemo(() => {
        return watch('poNumber')
    }, [watch('poNumber')]);

    // const packagePONumbers = useMemo(() => {
    //     return watch('poNumber')
    // }, [watch(`pickups.${packageIndex}.Item`)]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                backdropFilter: "blur(5px) sepia(5%)",
            }}
            PaperProps={{ sx: { borderRadius: '16px' } }}
        >
            <DialogContent
                style={{
                    overflow: "hidden",
                    padding: 0,
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    height={"100%"}
                    alignItems={"center"}
                >
                    <Box
                        display={"flex"}
                        flex={"column"}
                        borderRadius={"16px"}
                        bgcolor={"#f5f5f5"}
                        width={"100%"}
                        maxHeight={"95vh"}
                        overflow={"auto"}
                        padding={"30px"}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                borderRadius: "32",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                    mb: "2rem",
                                }}
                            >
                                <Typography
                                    fontWeight={600}
                                    variant="h1"
                                    sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
                                >
                                    Fill in the PO Numbers of the Packages
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                    mb: "1rem",
                                }}
                            >
                                {!isWarehouseManager && (
                                    <FormControl>
                                        <FormLabel sx={{ mb: 1 }}>Your PO Numbers</FormLabel>
                                        <PONumberField />
                                    </FormControl>
                                )}
                                {poNumbers?.length > 0 &&
                                    <PackagePOMapper />
                                }
                                <Box>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        onClick={() => onDone(state)}
                                        fullWidth
                                        disabled={false}
                                        sx={{ height: "2.5rem", mt: "2rem", mb: "1rem" }}
                                    >
                                        Done
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
