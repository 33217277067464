import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { ShipmentCard } from "./components/cards/ShipmentCard";
import { UserCard } from "./components/cards/UserCard";
import { LocationCard } from "./components/cards/LocationCard";

export function AuditsPage() {

  return (
    <>      
      <Container maxWidth="false">
        <Typography
          variant="h4"
          sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
          Audits
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container gap={2}>          
          <ShipmentCard/>          
          <LocationCard/>
          <UserCard/>
        </Grid>
      </Container>
    </>
  );
}