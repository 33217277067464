import { Box, FormControl, FormLabel, MenuItem, Stack, TextField, Typography } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { LocationsDetails } from "../details-drawer/LocationsDetails"
import { FormInput } from "src/components/form/FormInput";
import { LocationList } from "./LocationList";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CombinedShipmentsColumns } from "../../constants/combinedShipmentsColumns";
import { Equipments } from "../../constants/equipments";
import { useEffect } from "react";
import { useMemo } from "react";

export const CombineForm = () => {
    const modes = [{
        value: 'TL',
        hasMultiLegs: true,
    }, {
        value: 'PAR',
        hasMultiLegs: true,
    }, {
        value: 'LTL',
        hasMultiLegs: false,
    }];
    const { getValues, control, setValue, watch } = useFormContext();
    const { remove: removeShipment } = useFieldArray({ name: 'shipments', control });
    const { remove: removeShipmentId } = useFieldArray({ name: 'shipmentIds', control });
    const { remove: removeLocation } = useFieldArray({ name: 'locations', control });
    const { remove: removeItem } = useFieldArray({ name: 'items', control });

    const onRemoveTapped = (id) => {
        const ids = getValues('shipmentIds');
        const locations = getValues('locations');
        const items = getValues('items');
        const shipments = getValues('shipments');

        const shipmentIdIndex = ids.findIndex(v => v === id);
        const locationIndexes = locations.map((v, index) => v.shipmentId === id ? index : -1).filter(index => index !== -1) ?? -1;
        const itemIndexes = items.map((v, index) => v.shipmentId === id ? index : -1).filter(index => index !== -1) ?? -1;
        const shipmentIndex = shipments.findIndex(v => v._id === id);

        removeShipmentId(shipmentIdIndex);
        removeLocation(locationIndexes);
        removeItem(itemIndexes);
        removeShipment(shipmentIndex);
    }

    const mapEquipment = (selected) => {
        const equipments = getValues('equipment');
        const nonSelecteds = Object.keys(equipments).filter(eq => eq !== selected && eq !== 'selected');
        nonSelecteds.forEach(key => setValue(`equipment.${key}`, false));
        setValue(`equipment.${selected}`, true);
    }    

    useEffect(() => {
        const equipment = getValues('equipment.selected');
        if (!!equipment) mapEquipment(equipment);

    }, [watch('equipment.selected')]);

    const rows = useMemo(() => {
        const items = getValues('items');
        const shipments = getValues('shipments');

        return shipments.map(shipment => {
            const shipmentItems = items.filter(item => item.shipmentId === shipment._id);
            const pickup = shipment.pickups[0];
            const stop = shipment.stops[shipment.stops.length - 1];

            return shipmentItems.map((item, index) => ({
                id: `${shipment._id}-${index}`,
                hierarchy: [shipment.primaryReference, index],
                primaryReference: shipment.primaryReference,
                quantity: `${item.actualQty} ${item.actualQtyUOM}`,
                weight: `${item.actualWeight} ${item.actualWeightUOM}`,
                package: item.description,
                pickup: `${pickup.originName} - ${pickup.originAddr1}, ${pickup.originCity}, ${pickup.originState}`,
                stop: `${stop.destName} - ${stop.destAddr1}, ${stop.destCity}, ${stop.destState}`,
            }));
        }).flat(Infinity);
    }, [watch('shipments'), watch('items')]);

    const getThreeDataPath = (row) => row.hierarchy;

    const isMultiLegs = getValues('locations')?.length > 2;

    return (
        <Stack gap={2}>
            <Stack gap={2}>
                <Typography
                    variant="h2"
                    sx={{ "&.MuiTypography-h2": { fontSize: "1rem" } }}
                >
                    Select Combined Mode and Equipment
                </Typography>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <FormInput
                        label='Mode'
                        name='mode'
                        select
                        fullWidth
                        required
                    >
                        <MenuItem value="SELECT" disabled>Select Shipment Mode</MenuItem>
                        {modes
                            .filter((mode) => !isMultiLegs || (isMultiLegs && mode.hasMultiLegs))
                            .map((mode) => (
                                <MenuItem key={mode.value} value={mode.value}>
                                    {mode.value}
                                </MenuItem>
                            ))}
                    </FormInput>
                    <FormInput
                        label={'Equipment'}
                        name={'equipment.selected'}
                        select
                        fullWidth
                        required={watch('mode') !== 'LTL'}
                    >
                        {Equipments.map((eq) => (<MenuItem key={eq.key} value={eq.key}>{eq.label}</MenuItem>))}
                    </FormInput>
                </Box>
            </Stack>
            <Stack gap={2}>
                <Typography
                    variant="h2"
                    sx={{ "&.MuiTypography-h2": { fontSize: "1rem" } }}
                >
                    Selected Shipments
                </Typography>
                <DataGridPro
                    columns={CombinedShipmentsColumns(onRemoveTapped)}
                    rows={rows ?? []}
                    treeData
                    getTreeDataPath={getThreeDataPath}
                    groupingColDef={{
                        headerName: 'Group',
                    }}
                    isGroupExpandedByDefault={(node) => true}
                />
            </Stack>
            <Stack gap={2}>
                <Typography
                    variant="h2"
                    sx={{ "&.MuiTypography-h2": { fontSize: "1rem" } }}
                >
                    Pickups & Stop
                </Typography>
                <LocationList />
            </Stack>
        </Stack>
    );
}