import {
  Autocomplete,
  CircularProgress,
  TextField
} from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";

import { LocationService } from "src/api/location/location.service";
import { useAuth } from "src/hooks/useAuth";
import { ListItemLocationDetail } from "./SearchItemLocationDetails";

export function LocationSearchField({
  name,
  index = 0,
  locationType,
  label = "Location",
  placeholder = "Location Name",
  ...props
}) {
  const { setSnackbarMessage } = useSnackbar();
  const { session } = useAuth();
  const service = new LocationService(session.token, session.tenant._id);

  const { control, setValue, getValues } = useFormContext();

  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const abortControllerRef = useRef(null);

  const debounceEffect = debounce((location) => {
    if (location?.length && location != "") {
      const fetchData = async () => {
        setOptions([]);
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        try {
          const validatorBody = {
            name,
            value: location,
            isDelivery: locationType?.toLowerCase() === 'delivery',
          };

          const result = await service.search(
            validatorBody,
            abortControllerRef.current?.signal
          );

          // If fetch is successful, update options
          if (
            (result.default && result.default.length > 0) ||
            (result.search && result.search.length > 0)
          ) {
            const data = [...result.default, ...result.search];
            setOptions(data);
          }
          setIsLoading(false);
        } catch (err) {
          // Handle fetch errors
          if (err.name === "AbortError") {
            // Fetch was aborted
            console.log("Fetch was aborted.");
          } else {
            setIsLoading(false);

            // Fetch error occurred
            setSnackbarMessage({
              message: err.message,
              severity: "error",
            });
          }
        }
      };
      fetchData();
    }
  }, 1500);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function handleInputChange(event, value) {
    if (!event) return;
    debounceEffect(getValues(name));

    setIsLoading(!!value);
    setValue(name, value);

    return () => debounceEffect.cancel();
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;
        return (
          <Autocomplete
            {...field}
            freeSolo
            loading={loading}
            open={open}
            autoComplete
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            options={options}
            filterOptions={(x) => x}
            includeInputInList
            value={
              value
                ? options.find((option) => {
                    return value === option.name;
                  }) ?? typeof value === "string"
                  ? { name: value }
                  : null
                : null
            }
            onChange={(event, newValue) => {
              const location = newValue ? newValue.name : null;
              onChange(location);
            }}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                variant="outlined"
                placeholder={placeholder}
                fullWidth
                required
                label={label}
                {...error}
                InputProps={{
                  ...params.InputProps,
                  sx: { width: "100%", ...params?.InputProps?.sx },
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {props?.InputProps?.endAdornment}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItemLocationDetail
                key={option._id}
                customProps={props}
                location={option}
                type={locationType?.toLowerCase() ?? 'pickup'}
                index={index}
              />
            )}
          />
        );
      }}
    />
  );
}
