import { Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { ShipmentService } from "src/api/shipment.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Shipment } from "src/models/shipment";
import { SpotmarketForm } from "./components/form/SpotmarketForm";
import { useShipmentQueries } from "./hooks/useShipmentQueries";
import { SavingShipmentDialog } from "./components/SavingShipmentDialog";

export function UpdateSpotmarketShipmentPage() {
  const { shipmentId } = useParams();
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();
  const [shipment, setShipment] = useState(null);

  const searchParams = new URLSearchParams(useLocation().search);

  const [isDuplication] = useState(searchParams.get("duplicate") != null);
  const [type] = useState(isDuplication ? "duplicate" : "update");

  const service = new ShipmentService(session.token, session.tenant._id);
  const {
    updateSpotmarketShipment,
    isUpdateSpotmarketLoading,
    createSpotmarketShipment,
    isCreationSpotmarketLoading,
    deleteShipment,
    isDeletionLoading,
  } = useShipmentQueries();

  const { refetch: getShipment, isLoading } = useQuery("shipment", {
    queryFn: () =>
      service
        .getShipment(shipmentId)
        .then((shipment) => {
          if (shipment.message) {
            throw Error(shipment.message);
          }
          const editableShipment = Shipment.fromJson(shipment, type);

          setShipment(editableShipment);
        })
        .catch((error) => {
          setSnackbarMessage({
            message: `Failed to load shipment data - ${error}`,
            severity: "error",
          });
        }),
    enabled: false,
  });

  useEffect(() => {
    getShipment()
  }, [])

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Update Shipment</title>
      </Helmet>
      <SpotmarketForm
        type={type}
        loading={isLoading}
        useFormParams={{ defaultValues: shipment }}
        onSave={isDuplication ? createSpotmarketShipment : updateSpotmarketShipment}
        isLoading={isLoading || !shipment}
        isSubmissionLoading={isUpdateSpotmarketLoading || isCreationSpotmarketLoading}
        isBackOffice={session.permissions.IsBackOffice}
        handleRemove={deleteShipment}
      />
      <SavingShipmentDialog
        isOpen={isUpdateSpotmarketLoading || isCreationSpotmarketLoading}
      />
    </Paper>
  );
}
