import * as React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';

function DialogCombinedShipmentsWarning({ open, onClose, onConfirm, message }) {
    return (
        <StandardDialog isOpen={open} onClose={onClose}>
            <Box sx={{ width: '100%' }}>
                <DialogTitle>Combined Shipments</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button variant="text" onClick={onClose}>No</Button>
                    <Button variant="contained" onClick={onConfirm}>Yes</Button>
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogCombinedShipmentsWarning;