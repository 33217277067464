import { TaskAltOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTenant } from "src/hooks/useTenant";
import { useShipmentQueries } from "src/pages/shipments/hooks/useShipmentQueries";
import { formatDateString } from "src/utils/formatTime";
import DialogConfirmForSpotMarket from "../DialogConfirmForSpotMarket";
import { useShipmentDetails } from "src/pages/shipments/context/ShipmentDetailsContext";

export const CarrierCandidateDetails = () => {
  const { shipmentId } = useParams();

  const { 
    getCandidateCarrier, 
    candidateCarriers, 
    confirmCarrier, 
    updateSpotmarket, 
    isCandidateLoading } = useShipmentQueries();
  const { shipment } = useShipmentDetails();

  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [openConfirmSpotMarket, setOpenConfirmSpotMarket] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  useEffect(() => {
    getCandidateCarrier({ shipmentId });
  }, []);

  useEffect(() => {
    if (candidateCarriers) {
      const confirmed = candidateCarriers?.carriers?.some((item) => item.confirm === true);
      if (confirmed) setHasConfirmed(true);
    }
  }, [candidateCarriers]);

  const handleCarriers = () => {
    getCandidateCarrier({ shipmentId });
  };

  const openModalSpotmarket = ({ carrierId, shipmentId }) => {
    setSelectedCarrier({ carrierId, shipmentId });
    setOpenConfirmSpotMarket(true);
  };

  const confirmCarrierAndFillSpotmarket = (data) => {
    confirmCarrier({ carrierId: selectedCarrier.carrierId, shipmentId });
    updateSpotmarket(shipment);
    setOpenConfirmSpotMarket(false);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Candidates Carriers"}
        sx={{
          "&.MuiCardHeader-root": {
            p: 0,
          },
        }}
      />
      <Divider />
      <CardContent>
        <Box>
          {isCandidateLoading && <LinearProgress />}
          {!isCandidateLoading && !candidateCarriers && (
            <Typography>Failed to load candidate carriers</Typography>
          )}
          {!isCandidateLoading && candidateCarriers && (
            <Grid container gap={"1rem"}>
              {candidateCarriers?.carriers?.map((candidate) => (
                <CandidatesDetail
                  key={candidate._id}
                  candidate={candidate}
                  shipmentId={shipmentId}
                  onConfirm={handleCarriers}
                  disabled={hasConfirmed}
                  openModalSpotmarket={openModalSpotmarket}
                />
              ))}
            </Grid>
          )}
        </Box>
      </CardContent>

      <DialogConfirmForSpotMarket
        open={openConfirmSpotMarket}
        title={"Fill this information to confirm shipment"}
        text={"To continue the confirm shipment, please fill the information below "}
        shipment={shipment}
        useFormParams={{ defaultValues: shipment }}
        onSave={confirmCarrierAndFillSpotmarket}
        isLoading={false}
        onClose={() => setOpenConfirmSpotMarket(false)}
      />
    </Card>
  );
};

const CandidatesDetail = ({ candidate, shipmentId, onConfirm, disabled, openModalSpotmarket }) => {
  const { confirmCarrier } = useShipmentQueries();
  const { tenant } = useTenant();

  const Row = ({ title, value }) => (
    <Box sx={{ display: "flex", gap: "0.4rem" }}>
      <Typography>{title}</Typography>
      <Typography fontWeight={600}>{value}</Typography>
    </Box>
  );

  const carrierId = candidate._id;
  const formattedDate = formatDateString(
    new Date(candidate?.eta).toLocaleString("en-US")
  );

  const handleCandidateConfirm = () => {
    if (!tenant.modules?.spotmarket) {
      confirmCarrier(
        { carrierId, shipmentId },
        {
          onSuccess: () => {
            onConfirm();
          },
        }
      );
    } else {
      openModalSpotmarket({ carrierId, shipmentId });
    }
  };

  return (
    <Grid item>
      <Card variant="outlined" sx={{ px: "2rem", py: "1rem" }}>
        <Box sx={{ gap: "0.8rem" }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "1.1rem", mb: "1rem" }}
          >{`Rate: ${candidate.rate}`}</Typography>
          <Row title={"MC#: "} value={candidate.mc} />
          <Row title={"ETA: "} value={formattedDate} />
          <Row title={"Empty In: "} value={candidate.emptyOn} />
        </Box>
        {!candidate.confirm && (
          <Button
            variant="contained"
            disabled={disabled}
            onClick={handleCandidateConfirm}
            sx={{ width: "100%", mt: 1 }}
          >
            Confirm
          </Button>
        )}
        {candidate.confirm && (
          <LoadingButton
            startIcon={<TaskAltOutlined />}
            disabled={true}
            variant="outlined"
            color={"success"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
                width: "100%",
              },
            }}
          >
            {"Confirmed"}
          </LoadingButton>
        )}
      </Card>
    </Grid>
  );
};
