import { createContext, useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { palletCountUnits, palletCountLabel } from "../components/units";
import { FormStepperProvider } from "src/components/form/FormStepper";

const SpotmarketPackageFormContext = createContext();

export function SpotmarketPackageFormProvider({ children, packageIndex }) {
  const { watch, getValues } = useFormContext();

  const state = useMemo(() => {
    const item = getValues(`items.${packageIndex}`);
    return ({
      isShowSpecification: item?.description ?? false,
      quantityLabel: item?.actualQtyUOM
        ? palletCountLabel[
        palletCountUnits.findIndex((v) => v === item.actualQtyUOM)
        ]
        : palletCountLabel[12],
      isShowWeight:
        item?.actualLength && item?.actualWidth && item?.actualHeight,
      isShowQuantity: item?.actualWeight,
      isBasicInfoSet: !!(
        item?.itemId &&
        item?.actualValue &&
        item?.description
      ),
    })
  }, [
    watch(`items.${packageIndex}.actualLength`),
    watch(`items.${packageIndex}.actualWidth`),
    watch(`items.${packageIndex}.actualHeight`),
    watch(`items.${packageIndex}.actualQty`),
    watch(`items.${packageIndex}.actualWeight`),
  ]);

  const isHazmatCargo = watch('services.hazardousCargoCharge');
  const isHazmatPackage = watch(`items.${packageIndex}.isHazmat`);

  const steps = useMemo(() => [
    {
      label: "Basic Info",
      completed: state.isBasicInfoSet,
      isValid: !!(
        state.isShowWeight &&
        state.isShowQuantity &&
        state.isShowAdditionalInfo
      ),
    },
  ], [state]);

  return (
    <FormStepperProvider steps={steps}>
      <SpotmarketPackageFormContext.Provider
        value={{ state, packageIndex, isHazmatCargo, isHazmatPackage }}
      >
        {children}
      </SpotmarketPackageFormContext.Provider>
    </FormStepperProvider>
  );
}

export function useSpotmarketPackageForm() {
  const context = useContext(SpotmarketPackageFormContext);

  if (!context) {
    throw new Error("useSpotmarketPackageForm must be used within a SpotmarketPackageFormProvider");
  }

  return context;
}
