import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { valueGetter } from "src/pages/shipments/components/details-drawer/DetailsDrawer";
import { fCurrency } from "src/utils/formatNumber";
import { HazardousDetails } from "./HazardousDetails";
import {
  palletCountUnits,
  palletCountLabel,
} from "src/pages/shipments/components/units";
import { useAuth } from "src/hooks/useAuth";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { FileUtils } from "src/utils";
import { SubItems } from "./SubItemDetails";
import { useTenant } from "src/hooks/useTenant";

export function ItemDetails({ item, ...props }) {

  const { tenant } = useTenant();
  const isSpotmarket = tenant.modules?.spotmarket;

  const qtyLabel =
    palletCountLabel[
      palletCountUnits.findIndex((v) => v === item.actualQtyUOM)
    ];
  const { session } = useAuth();
  const auth = session.permissions;
  const isCoxSupplier =
    session.permissions.IsSupplierManager && session?.tenant?.code === "COX";
  const [allowPackageUpload, setAllowPackageUpload] = useState(
    !item.packageSlip
  );

  const toggleAllowUploadPackage = () => {
    setAllowPackageUpload(!allowPackageUpload);
  };

  return (
    <Stack spacing={2} direction={"column"}>
      <Accordion
        variant="outlined"
        defaultExpanded={props.expanded ? props.expanded : false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          sx={{ display: "flex", gap: 2 }}
        >
          <Box
            component="span"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              py: "0.25rem",
            }}
          >
            { isSpotmarket ? (
              <Box display={"flex"} gap={1}>
                <Typography color={"GrayText"}>Description:</Typography>
                <Typography>
                  {valueGetter(item.description)}
                </Typography>
              </Box>
            ) : (
              <Box display={"flex"} gap={1}>
                <Typography color={"GrayText"}>Package ID -</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.itemId)}
                </Typography>
              </Box>
            )}
            
            {isCoxSupplier && (
              <Box display={"flex"} gap={1}>
                <Typography color={"GrayText"}>PO Number -</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.poNumber)}
                </Typography>
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Card
            variant="elevation"
            elevation={0}
            sx={{
              py: "0.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
              "&.MuiCard-root": {
                p: 0,
              },
            }}
          >
            <CardContent
              sx={{
                "&.MuiCardContent-root": {
                  p: 0,
                },
              }}
            >
              {/* PO Number */}
              { !isSpotmarket && 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Description</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.description)}
                </Typography>
              </Box>
              }

              {/* Shipment Number */}
              { !isSpotmarket && 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Value</Typography>
                <Typography fontWeight={600}>
                  {fCurrency(item.actualValue)}
                </Typography>
              </Box>
              }

              {/* Customer */}
              { !isSpotmarket && 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Class</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.class)}
                </Typography>
              </Box>
              }

              {/* COX PO NUMBER */}
              { !isSpotmarket && 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>NMFC Code</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.nmfcCode)}
                </Typography>
              </Box>
              }

              { !isSpotmarket && 
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Manufacturer Part Number</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.manufacturerPartNumber)}
                </Typography>
              </Box>
              }

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Weight</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualWeight} ${valueGetter(
                    item.actualWeightUOM
                  )} - ${valueGetter(item.actualWeightCountType)
                    .split("_")
                    .join(" ")}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>{`${qtyLabel} count`}</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualQty} - ${item.actualQtyUOM}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Dimensions</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualLength} x ${item.actualWidth} x ${item.actualHeight} ${item.actualDimensionsUOM} `}
                </Typography>
              </Box>
              {item.actualPieces && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                  }}
                >
                  <Typography>{`Piece count`}</Typography>
                  <Typography fontWeight={600}>
                    {`${item.actualPieces} - ${item.actualPiecesUOM}`}
                  </Typography>
                </Box>
              )}
              {item.isHazmat && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  marginTop={"4px"}
                >
                  <Divider />
                  <Typography
                    variant="h1"
                    sx={{
                      "&.MuiTypography-h1": {
                        fontSize: "1.1rem",
                      },
                      mt: "16px",
                      mb: "4px",
                    }}
                  >
                    Hazmat Details
                  </Typography>
                  <HazardousDetails hazardous={item.hazardous} />
                </Box>
              )}
            </CardContent>
          </Card>
          {item?.packageSlip && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",  
                  py: "0.70rem",                                    
                }}
              >
                <Typography>Package Slip</Typography>
                <Box sx={{ display: "flex"}}>
                  <Button
                    href={
                      item.packageSlip.url
                        ? item.packageSlip.url
                        : "#"
                    }
                    sx={{
                      display: "flex",
                      width: "9rem",
                      px: 1,      
                      gap:1,
                      borderRadius: "0.5rem",
                      fontWeight: 800,
                      boxShadow: 1,
                      
                    }}
                  >                    
                  <DownloadIcon></DownloadIcon>
                  Download
                </Button>
              </Box>
            </Box>
          )}
          {item?.subItems.map((subItem) => (
            <SubItems subItem={subItem} />
          ))}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
