import { Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Pagination, Typography } from "@mui/material";
import { JsonViewer } from "@textea/json-viewer";
import { fDateString } from "src/utils/formatTime";
import { useState } from "react";

export const UsersDetails = ({ audits, isLoading }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 5

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    let indexOfLastItem = '';
    let indexOfFirstItem = '';
    let currentAudits = '';    

    if(audits) {
        console.log(audits)
        indexOfLastItem = currentPage * itemsPerPage;
        indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentAudits = audits?.slice(indexOfFirstItem, indexOfLastItem);    
    }

    return (
        <Card
            variant="outlined"
            sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
            }}
        >
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography fontWeight={1000}>Users Details</Typography>
                <Typography fontWeight={1000}>Quantity audits: {audits?.length}</Typography>
            </Box>
            <Divider />
            <CardContent>
                <Box>
                    {isLoading && <LinearProgress />}
                    {audits?.length === 0 && <Typography>Failed to load audits</Typography>}
                    {currentAudits &&
                        <Box sx={{ gap: "2rem" }}>
                            {currentAudits?.map((audit, index) => (<UserDetail key={index} audit={audit} />))}
                        </Box>
                    }
                    {audits?.length > itemsPerPage && (
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                            <Pagination
                                count={Math.ceil(audits.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

const UserDetail = ({ audit }) => {

    const Row = ({ title, value }) => {
        return <Box sx={{ display: 'flex', gap: '0.4rem' }}>
            <Typography>{title}</Typography>
            <Typography fontWeight={600}>{value}</Typography>
        </Box>;
    }

    return (
        <Card variant="outlined" sx={{ px: '2rem', py: '1rem', mt: '1rem' }} fullWidth>
            <Box sx={{ gap: '0.8rem' }} fullWidth>
                <Row title={'Audit Date: '} value={fDateString(audit.timestamp)} />
                <Row title={'Method: '} value={audit.request.method} />
                <Row multiline title={'Route: '} value={audit.route} />
                <Row title={'URL: '} value={audit.request.url} />
                <Row title={'User Id: '} value={audit.user} />
                <JsonViewer value={audit.response.body} rootName={"Users"} maxDisplayLength={5} />
            </Box>
        </Card>

    );
}