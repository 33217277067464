import React from "react";
import { StandardDialog } from "src/components/dialog/Dialog";
import { Box } from "@mui/material";
import { ContactUsContent } from "./ContactUsContent";

export const ContactUsDialog = (props) => {
  return (
    <StandardDialog isOpen={props.isOpen} onClose={props.handleClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <ContactUsContent
          isSupportOnly={props.isSupportOnly}
          name={props.name}
          email={props.email}
          phone={props.phone}
        />
      </Box>
    </StandardDialog>
  );
};
