import * as React from 'react';
import { Box, Button, Card, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { ShipmentFormProvider } from '../../context/ShipmentFormContext';
import { FieldsDialogConfirm } from './FieldsDialogConfirm';
import { LoadingButton } from '@mui/lab';

function DialogConfirmForSpotMarket(props) {
    const locationPickup = props?.shipment?.pickups[0];
    const locationStop = props?.shipment?.stops[0];

    return (
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
            <ShipmentFormProvider
                isSpotmarket
                reset={false}
                onSave={props.onSave}
                useFormParams={props.useFormParams}
                sx={{ width: "100%" }}
            >
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>{props.text}</DialogContentText>
                    <Card
                        variant="outlined"
                        sx={{
                            py: "1.5rem",
                            px: "1rem",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.75rem",
                            background: "transparent",
                        }}
                    >
                        <FieldsDialogConfirm
                            locationPickup={locationPickup}
                            locationStop={locationStop}
                            shipment={props.shipment}
                        />
                    </Card>
                </DialogContent>
                <DialogActions sx={{ pb: 2, pr: 3 }}>
                    <Button variant="outlined" onClick={props?.onClose} >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={props.isUpdateSpotLoading}
                        type="submit"
                    >
                        Redirect
                    </LoadingButton>
                </DialogActions>
            </ShipmentFormProvider>
        </StandardDialog>
    );
}

export default DialogConfirmForSpotMarket;