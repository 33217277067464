import { Box, Button, Container, Paper, Stack, Typography } from "@mui/material"
import { ContactUsContent } from "./contact-us/ContactUsContent"
import Logo from "src/components/logo"
import { useNavigate } from "react-router-dom"

export const ContactUsPage = () => {
    const navigate = useNavigate();
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "90vh",
                py: 8,
            }}
        >
            <Stack gap={2}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        // mb: 2,
                        // py: 1,
                    }}
                >
                    <Logo sx={{ width: "5rem" }} />
                    <Typography
                        variant="h1"
                        sx={{
                            "&.MuiTypography-h1": { fontSize: "2.5rem" },
                            width: "fit-content",
                        }}
                    >
                        Reach out to our team!
                    </Typography>
                </Box>

                <Paper
                    elevation={3}
                    sx={{
                        width: "100%",
                        display: "flex",
                        borderRadius: 4,
                        maxWidth: "560px",
                    }}
                >
                    <ContactUsContent />
                </Paper>
                <Paper
                    elevation={3}
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 4,
                        maxWidth: "560px",
                        p: "1.2rem",
                        gap: 1,
                    }}
                >
                    <Typography variant="body1">Would you like to go back to the platform?</Typography>
                    <Button onClick={() => navigate('/')}>GO TO PLATFORM</Button>
                </Paper>
            </Stack>
        </Container>
    )
}