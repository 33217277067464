import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { useAuth } from "src/hooks/useAuth";
import { NumericFormat } from 'react-number-format';
import { FormInput } from "src/components/form/FormInput";
import { status } from "src/models/spotmarketStatus";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

export function SpotmarketRateFields(props) {
  const { session } = useAuth();
  const { control, getValues, watch } = useFormContext();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Rates & Additional Info"}
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: '1rem' },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",

        }}
      >

        {/* SPOTMARKET FIELDS */}
        <Stack sx={{ mb: 3 }}>
          <FormControl>
            <InputLabel id="status-label">Status</InputLabel>
            <Controller
              control={control}
              name="metadata.spotmarket.status"
              render={({ field }) => (
                <Select
                  {...field}
                  multiple
                  label="Status"
                  fullWidth
                  value={field.value || []}
                  MenuProps={MenuProps}
                >
                  { status.length > 0 && (
                    status.map((status_item) => (
                      <MenuItem key={status_item.key} value={status_item.key}>
                        {status_item.label}
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
            />
          </FormControl>
        </Stack>
        <Stack sx={{ mb: 3 }}>
          <FormInput
            label="Customer"
            name={`customer`}
            type="text"
            fullWidth
          />
        </Stack>
        <Stack 
          sx={{ mb: 3 }}
          direction={{ xs: "column", md: "row" }} 
          spacing={2}
        >
          {/* Posted Rate */}
          <Controller
            name="metadata.spotmarket.postedRate"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Posted Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />
        </Stack>

        {/* Truck Rate Range */}
        <Stack 
          direction={{ xs: "column", md: "row" }} 
          spacing={2}
        >
          <Controller
            name="metadata.spotmarket.truckRateRange.min"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Initial Truck Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />

          <KeyboardDoubleArrowRight 
            sx={{ 
              alignSelf: 'center',
              color: (theme) => theme.palette.primary.main,
            }} 
          />

          <Controller
            name="metadata.spotmarket.truckRateRange.max"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Maximum Truck Rate"
                fullWidth
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                inputProps={{
                  thousandSeparator: false,
                  decimalSeparator: ".",
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
              />
            )}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
