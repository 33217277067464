import { Box, Paper, Typography } from "@mui/material";
import { useCityQueries } from "./hooks/useCityQueries";
import { Helmet } from "react-helmet-async";
import { CityForm } from "./form/CityForm";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useNavigate } from "react-router-dom";

export function CreateCityPage() {
    const { create, isCreating } = useCityQueries();
    const { setSnackbarMessage } = useSnackbar();
    const navigate = useNavigate();

    const onSave = async (city) => {        
        create({
            city,
            onCreated: (data) => {
                if (data.errors) setSnackbarMessage({
                    message: (<Box> Error submitting location.</Box>),
                    severity: "error",
                });
                else {
                    setSnackbarMessage({
                        message: `${data.city} and its alternative cities successfully created`,
                        severity: "success",
                    });
                    navigate(`/cities`);
                }
            },
            onFailed: (message) => {
                setSnackbarMessage({
                    message: (<Box> {`${message}`}</Box>),
                    severity: "error",
                })
            },
        });
    }

    return (
        <Paper sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3, }} elevation={3}>
            <Helmet><title>Create City and Alternative Cities</title></Helmet>
            <Box sx={{ maxWidth: '80%', margin: 'auto' }}>
                <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
                    Create City and Alternative Cities
                </Typography>
                <CityForm
                    onSave={onSave}
                    isSubimissionLoading={isCreating}
                />
            </Box>
        </Paper>
    );
}