import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  TextField
} from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormAccordion } from "src/components/form/FormAccordion";
import { PickupAddressFields } from "./SpotmarketPickupFields";
import { StopAddressFields } from "./SpotmarketStopFields";

export function SpotmarketCoreFields(props) {
  const { control, getValues, setValue, watch } = useFormContext();

  const [isDummyDisabled, setIsDummyDisabled] = useState(false);
  const initialIsDummy = useRef(getValues("metadata.spotmarket.dummy.isDummy") || false);

  useEffect(() => {
    if (initialIsDummy.current) {
      setIsDummyDisabled(true);
    }
  }, []);

  const handleSwitchChange = (event) => {
    const newValue = event.target.checked;
    if (!isDummyDisabled) {
      setValue("metadata.spotmarket.dummy.isDummy", newValue);
    }
  };

  const currentIsDummy = watch("metadata.spotmarket.dummy.isDummy");

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={watch("primaryReference") ?? "Basic info"}
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
        action={
          <FormControlLabel
            labelPlacement="end"
            label={"Dummy"}
            control={
              <Controller
                name={`metadata.spotmarket.dummy.isDummy`}
                control={control}
                render={({ field: { ref } }) => (
                  <Switch
                    checked={currentIsDummy}
                    disabled={isDummyDisabled}
                    inputRef={ref}
                    onChange={handleSwitchChange}
                  />
                )}
              />
            }
          />
        }
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: "1rem" },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <FormAccordion title={"Locations"}>
          <Stack direction={{ xs: "column", md: "row" }}>
            <PickupAddressFields />
            <Box sx={{ width: "5%", height: "5%" }} />
            <StopAddressFields />
          </Stack>
        </FormAccordion>
      </CardContent>
    </Card>
  );
}