import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";

export const CombinedShipmentsColumns = (handleRemove) => [
    {
        field: "primaryReference",
        headerName: "Primary Reference",
        minWidth: 140,
    },
    {
        field: "package",
        headerName: "Package",
        minWidth: 225,
    },
    {
        field: "quantity",
        headerName: "Quantity",
    },
    {
        field: "weight",
        headerName: "Weight",
        width: 120,
    },
    {
        field: "pickup",
        headerName: "Shipper",
        minWidth: 400,
    },
    {
        field: "stop",
        headerName: "Destination",
        minWidth: 400,
    },
    {
        field: "actions",
        headerName: "Remove",
        width: 68,
        align: "center",
        renderCell: (params) => {
            let id = '';
            const node = params.rowNode;
            if (node.type === 'group') id = params.rowNode.children[0].split('-')[0];
            else if (node.type === 'leaft') id = params.rowNode.id.split('-')[0];
            return (
                <Button onClick={() => handleRemove(id)}>
                    <Close />
                </Button>
            )
        },
    },
];
