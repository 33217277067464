import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TenantService } from "src/api/tenant.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useTenantQueries() {
  const { session } = useAuth();
  const service = new TenantService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();

  const navigate = useNavigate();
  const { tenantId } = useParams();

  const {
    isLoading: isLoadingTenants,
    isRefetching: isRefetchingTenants,
    data: tenants,
    refetch: getTenants,
  } = useQuery(`tenants`, {
    queryFn: async () => {
      return service.getTenants().catch((error) => {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error listing reports</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      });;
    },
    enabled: false,
  });

  const {
    isLoading: isLoadingTenant,
    isRefetching: isRefetchingTenant,
    data: tenant,
    refetch: getTenant,
  } = useQuery([`tenant`, tenantId], {
    queryFn: async () => {
      return service.getTenant(tenantId);
    },
    enabled: false,
  });

  const { mutate: createTenant, isLoading: isCreationLoading } = useMutation({
    mutationFn: (values) =>
      service
        .createTenant(values)
        .then((data) => {          
          setSnackbarMessage({
            message: `New tenant created: ${data.code}`,
            severity: "success",
          });

          navigate("/tenants");

          return data;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>Error creating Tenant</Typography>
                <Typography>{error.message}</Typography>
              </Box>
            ),
            severity: "error",
          });
        }),
  });

  const { mutate: updateTenant, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (tenant) =>
      service.updateTenant(tenant._id, tenant).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Tenant</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg} - {error.param}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message || "Error to update Tenant"}
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Tenant successfully updated!`,
          severity: "success",
        });
        navigate(`/tenants/${tenant._id}`);
        return data;
      }),
  });

  const { mutate: deleteTenant, isLoading: isDeletionLoading } = useMutation({
    mutationFn: (tenantId) => service.deleteTenant(tenantId),
    onSuccess: (data) => {
      if (data.errors) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography>Error deleting Tenant</Typography>
              <ErrorMessage response={data} />
            </Box>
          ),
          severity: "error",
        });
      } else {
        setSnackbarMessage({
          message: `Tenant successfully deleted!`,
          severity: "success",
        });
      }

      return data;
    },
  });

  return {
    isLoadingTenants,
    isRefetchingTenants,
    tenants,
    getTenants,

    createTenant,
    isCreationLoading,

    updateTenant,
    isUpdateLoading,

    isLoadingTenant,
    isRefetchingTenant,
    tenant,
    getTenant,
  };
}
