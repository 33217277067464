import { IconButton } from "@mui/material";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { UpdateShipmentDialog } from "../UpdateShipmentDialog";

export const EditIconButton = ({ isBackOffice, shipment, onEditTapped }) => {
    const [openModal, setOpenModal] = useState(false);
    const showModal = () => {
        setOpenModal(true);
    };

    const allowedStatus = ['NEW TENDER', 'READY'];
    const isEditable = !shipment.integrated === true && allowedStatus.some(status => status === shipment.status.toUpperCase());

    const onHandleClose = () => setOpenModal(false);

    return <>
        <IconButton            
            onClick={() => {
                if ((!isBackOffice && !isEditable) || (!isBackOffice && shipment?.combined)) {
                    showModal();
                } else {
                    onEditTapped();
                }
            }}
            sx={{
                py: "0.8rem",
                width: "2rem",
                height: "2rem",
                color: 'inherit',
                disabled: isBackOffice
            }}
        >
            <EditIcon />
        </IconButton>
        <UpdateShipmentDialog isOpen={openModal} onHandleClose={onHandleClose} shipment={shipment} />
    </>;
}