import {
  Location
} from "./location";
import {
  Service
} from "./service";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;

export class Order {
  static getStop(order, isLocationFormatted) {
    if (order.stop) {
      return {
        ...Location.toShipment(order.stop, "delivery", isLocationFormatted),
      };
    } else {
      const [, city, state, zip] = order.CITY_ST_ZIP.match(cityStateZipREGEX);

      return {
        type: 'delivery',
        name: order.SHIP_TO_LOCATION ?? "",
        address1: order.ADDRESS1 ?? "",
        address2: order.ADDRESS2 ?? "",
        city: city,
        state: state,
        zip: zip,
      };
    }
  }

  static getPickup(order, isLocationFormatted) {
    if (!order.pickup) {
      return {
        type: 'pickup',
        [isLocationFormatted === 'location' ? 'name' : 'originName']: order.VENDOR_NM ?? "",
      }
    }

    const isArray = Array.isArray(order.pickup);
    if (isArray && order.pickup.length) {
      return {
        ...Location.toShipment(order.pickup[0], "pickup", isLocationFormatted),
      };
    }

    return {
      ...Location.toShipment(order.pickup, "pickup", isLocationFormatted),
    };
  }

  static toShipment({
    order,
    ignoreShipmentNumber = false,
    isLocationFormatted = true,
  }) {
    const pickup = this.getPickup(order, isLocationFormatted);
    const stop = this.getStop(order, isLocationFormatted);
    const shipmentNumber = !ignoreShipmentNumber ? [order.SHIPMENT_NUM ?? ""] : [];

    if (isLocationFormatted) {
      return {
        shipmentNumber,
        locations: [
          pickup,
          stop,
        ],
        services: {
          ...Service.fromLocation(pickup, 'pickup'),
          ...Service.fromLocation(stop, 'stop'),
        },
      };
    }

    return {
      shipmentNumber,
      pickups: [pickup],
      stops: [stop],
      services: {
        ...Service.fromPickup(pickup),
        ...Service.fromStop(stop),
      },
    };
  }

  static getPONumber(order) {
    return order ? `${order?.PO_NUM}${order?.RELEASE_NUM != undefined ? "-" + order.RELEASE_NUM : "" }` : "";
  }
}