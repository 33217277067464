import AccountCircle from '@mui/icons-material/AccountCircleOutlined';
import { Box, Divider, IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { useTenant } from 'src/hooks/useTenant';

export default function AccountPopover() {
  const { logout, session: { user } } = useAuth();
  const { clearTenant } = useTenant();

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: 'eva:home-fill',
      onClick: (navigate) => {
        navigate(`/`);
      }
    },
    {
      label: 'Profile',
      icon: 'eva:person-fill',
      onClick: (navigate) => {
        navigate(`/users/${user.id}/edit`);
      }
    }
  ];

  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogoutClick = () => {
    logout();
    navigate('/login');
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size="large"
      >
        <AccountCircle />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.firstname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => {
              option.onClick(navigate);
              handleClose();
            }}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={clearTenant} sx={{ m: 1 }}>
          Change Tenant
        </MenuItem>        

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogoutClick} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
