import {
	Grid,
	Box,
	Typography,
	LinearProgress,
	Card,
	CardHeader,
	Divider,
	CardContent,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import {
	RadioButtonCheckedOutlined
} from "@mui/icons-material";
import { useShipmentQueries } from "src/pages/shipments/hooks/useShipmentQueries";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export function IntegrationsDetails() {	
    const { getShipment, shipment, isShipmentLoading } = useShipmentQueries();

	const { shipmentId } = useParams();	
	useEffect(() => {
		getShipment(shipmentId)
	}, [])

	const theme = useTheme();

	const INTEGRATIONS_LAST_ELEMENT_INDEX = shipment?.length - 1;

	const renderDivider = () => (
		<div style={{ width: 1, height: '100%', backgroundColor: '#CECECE' }} />
	);

	const renderItem = (integration, index) => {
		const errorMessage = integration?.metadata?.ERRORS?.map(error => error.PO_NBR + ' ' + error.ERROR)
		
		return (

			<Grid key={index} container sx={{ paddingBottom: '0.5rem' }}>

				<Grid sx={{
					display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 4, [theme.breakpoints.down('sm')]: {
						display: 'none',
					}
				}} >
					<RadioButtonCheckedOutlined fontSize={"0.4rem"} color="primary" sx={{ marginBottom: 1, paddingTop: '0.2rem' }} />
					{index !== INTEGRATIONS_LAST_ELEMENT_INDEX && renderDivider()}
				</Grid>

				<Grid>
					<Typography sx={{ color: "text.secondary" }} fontSize={"0.9rem"}>{integration.target.toUpperCase()}</Typography>

					<Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>Status: {integration.status}</Typography>
					<Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>Note: {integration.note}</Typography>
					<Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>Metadata</Typography>
					<Box sx={{ ml: 2}}>
					{integration?.metadata?.bolNumber && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- BOL Number: {integration.metadata.bolNumber}</Typography>}					
					{integration?.metadata?.loadId && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Load ID: {integration.metadata.loadId}</Typography> }					
					{integration?.metadata?.carrier && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Carrier: {integration.metadata.carrier}</Typography> }					
					{integration?.metadata?.bolUrl && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- BOL Number: {integration.metadata.bolUrl}</Typography> }					
					{integration?.metadata?.quoteId && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Quote ID: {integration.metadata.quoteId}</Typography> }					
					{integration?.metadata?.RESPONSE_CODE && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Response Code: {integration.metadata.RESPONSE_CODE}</Typography> }					
					{integration?.metadata?.RESPONSE_MESSAGE && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Response Message: {integration.metadata.RESPONSE_MESSAGE}</Typography> }							
					{integration?.metadata?.TRINITY_ID && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- Trinity ID: {integration.metadata.TRINITY_ID}</Typography> }		
					{integration?.metadata?.ASN_NBR && <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>- ASN NBR: {integration.metadata.ASN_NBR}</Typography> }							
					</Box>
					{integration?.metadata?.ERRORS && <Typography  fontWeight="600" sx={{ mt: 1 }} fontSize={"0.9rem"}>Error: {errorMessage}</Typography>}
				</Grid>
			</Grid>
		)
	};


	const renderIntegrations = () => (
		shipment?.integrations?.map((integration, index) => (
			renderItem(integration, index)
		))
	);

	const renderEmptyList = () => (
		<Typography variant="body1" sx={{ color: "text.secondary" }}>
			No Integrations updates yet.
		</Typography>
	);

	return (
		<Card
			variant="outlined"
			sx={{
				py: "0.5rem",
				px: "1rem",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "0.75rem",
			}}
		>
			<CardHeader
				title={"Integrations Details"}
				sx={{
					"&.MuiCardHeader-root": {
						p: 0,
					},
				}}
			/>
			<Divider />
			<CardContent>
				<Box>
					{isShipmentLoading && <LinearProgress />}
					{!isShipmentLoading && <>{shipment?.integrations?.length ? renderIntegrations() : renderEmptyList()}</>}

				</Box>
			</CardContent>
		</Card>
	);
}
