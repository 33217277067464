import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { getAudits } from "src/api/audits.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useSnackbar } from "src/hooks/useSnackbar";

export const useAuditsQueries = () => {

    const { setSnackbarMessage } = useSnackbar();
    
    const {
        isLoading: isAuditsLoading,
        mutate: get,
        data: audits,
      } = useMutation({
        mutationFn: (query) =>            
            getAudits(query).then((response) => {            
            if (response.errors || response.messages || response.message) {
              setSnackbarMessage({
                message: (
                  <Box>
                    <Typography mb={1} fontWeight={600}>
                      Error to get Audits
                    </Typography>
                    <ErrorMessage response={response} />
                  </Box>
                ),
                severity: "error",
              });
            }
            if (response.length == 0) {
              setSnackbarMessage({
                message: (
                  <Box>
                    <Typography mb={1}>No Audits Available</Typography>
                  </Box>
                ),
                severity: "warning",
              });
            }
            return response           
          }),
        onError: (error) => {
          setSnackbarMessage({
            message: error.message,
            severity: "error",
          });
        },
      });    

    return {
        get,
        isAuditsLoading,
        audits
    }
}