import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
} 

export function formatDateWithHour(date, hour) {
  const splitDate = date.split("T");
  const [splittedHour, splittedMinute] = hour.split(":");
  date = dayjs(splitDate[0] + " " + splittedHour + ":" + splittedMinute)
    .locale("en")
    .format("YYYY-MM-DD HH:mm")
    .split(" ")
    .join("T");
  return date;
}

export const fDateString = (dateString) => {
  if (!dateString) return ' -- ';

  const date = dayjs.utc(dateString); // Create a DayJS UTC object
  const formattedDate = date.format('MM/DD/YYYY HH:mm'); // Format the date

  return formattedDate;
};

export const fDateStringWithoutHours = (dateString) => {
  if (!dateString) return ' -- ';

  const date = dayjs.utc(dateString); // Create a DayJS UTC object
  const formattedDate = date.format('MM/DD/YYYY'); // Format the date

  return formattedDate;
};

export const formatDateString = (dateString) => {
  if (!dateString) return ' -- ';

  const date = new Date(dateString);

  const optionsDate = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedDate} - ${formattedTime}`;
};


export function getDayjsDate(dateFieldValue) {
  const isDayjs = !!dateFieldValue?.isValid && dateFieldValue?.isValid();

  return  isDayjs ? dateFieldValue : dateFieldValue ? dayjs.utc(dateFieldValue) : null;
}