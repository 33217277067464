import { createContext } from "react";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";
import { useShipmentCombineFormHook } from "../hooks/useShipmentCombineFormHook";
import { ShipmentUtils } from "src/utils";

export const ShipmentCombineFormContext = createContext(null);

export function ShipmentCombineFormProvider({ children, combinedShipment, onSave, reset, sx }) {
  const {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,
    ...rest
  } = useShipmentCombineFormHook({
    useFormParams: {
      defaultValues: combinedShipment,
    },
    onSave,
    reset
  });

  return (
    <ShipmentCombineFormContext.Provider
      value={{
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,
        ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {
            clearErrors();
            handleSubmit(onSubmitHandler)(event);
          }}
          sx={sx}
        >
          {children}
        </Box>
      </FormProvider>
    </ShipmentCombineFormContext.Provider>
  );
}
