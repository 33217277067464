import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  Typography,
  Stack,
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { getDayjsDate } from "src/utils/formatTime";
import { usaStates } from "../../../warehouses/constants/usaStates";
import { countries } from "../../constants/countries";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { defaultTimezone, timezones } from "src/utils/timezones";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { FormInputMask } from "src/components/form/FormInputMask";
import { FormInput } from "src/components/form/FormInput";
import { useTenant } from "src/hooks/useTenant";
import { debounce } from "lodash";
import { LocationService } from "src/api/location/location.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useCityQueries } from "src/pages/city/hooks/useCityQueries";

export function PickupAddressFields({ itemIndex = 0, ...props }) {
  const { tenant } = useTenant();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const { 
    alternativeCities, 
    getCitiesFromLocation 
  } = useCityQueries();

  const [minEarlyDate] = useState(dayjs());
  const [minLateDate, setMinLateDate] = useState(dayjs());
  const [tz, setTz] = useState(null);
  const abortControllerRef = useRef(null);
  const [locations, setLocation] = useState([]);
  const { session } = useAuth();
  const service = new LocationService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();  
  // const [alternativeCities, setAlternativeCities] = useState([]);

  const debounceEffect = debounce((location) => {
    if (location?.length && location != "") {
      const fetchData = async () => {
        setLocation([]);
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        const locationType = 'pickup'
        const name = `pickups.${itemIndex}.originName`
        try {
          const validatorBody = {
            name,
            value: location,
            isDelivery: locationType === 'delivery',
          };

          const result = await service.search(
            validatorBody,
            abortControllerRef.current?.signal
          );

          // If fetch is successful, update options
          if ((result.default && result.default.length > 0) || (result.search && result.search.length > 0)) {
            const data = [...result.default, ...result.search];
            setLocation(data);
          }              
        } catch (err) {
          // Handle fetch errors
          if (err.name === "AbortError") {
            // Fetch was aborted
            console.log("Fetch was aborted.");
          } else {                

            // Fetch error occurred
            setSnackbarMessage({
              message: err.message,
              severity: "error",
            });
          }
        }
      };
      fetchData();
    }
  }, 1500);            

  useEffect(() => {
    setMinLateDate(getDayjsDate(minEarlyDate));
  }, [minEarlyDate]);

  useEffect(() => {
    setMinLateDate(
      getDayjsDate(getValues(`pickups.${itemIndex}.targetShipEarly`))
    );
  }, [watch(`pickups.${itemIndex}.targetShipEarly`)]);

  useEffect(() => {
    const timezone = getValues(`pickups.${itemIndex}.timezone`);
    if (timezone)
      setTz(timezones.find((t) => t.name === (timezone || defaultTimezone)));
  }, [watch(`pickups.${itemIndex}.timezone`)]);  

  useEffect(() => {
    const name = getValues(`pickups.${itemIndex}.originName`);
    if (name) {                  
      debounceEffect(getValues(`pickups.${itemIndex}.originName`))            
    }
  }, [watch(`pickups.${itemIndex}.originName`)]);

  const getStates = () => {
    const country = getValues(`pickups.${itemIndex}.originCtry`);
    if (country === "CA") {
      return canadaStates;
    } else if (country === "MX") {
      return mexicoStates;
    } else {
      return usaStates;
    }
  };

  const [states, setStates] = useState(getStates());

  useEffect(() => {
    if (
      !states.some(
        (state) =>
          state.acronym == getValues(`pickups.${itemIndex}.originState`)
      )
    )
      setValue(`pickups.${itemIndex}.originState`, "");
  }, [states]);

  useEffect(() => {
    const validateFields = debounce(() => {
      const city = getValues(`pickups.${itemIndex}.originCity`) || props?.location?.originCity;
      const state = getValues(`pickups.${itemIndex}.originState`) || props?.location?.originState;
      const country = getValues(`pickups.${itemIndex}.originCtry`) || props?.location?.originCtry;      

      if (city && state && country) {
        getCitiesFromLocation({
          city,
          state,
          ctry: country,
        })
      }
    }, 1500);
  
    validateFields();
    return () => validateFields.cancel();
  }, [
    watch(`pickups.${itemIndex}.originCity`),
    watch(`pickups.${itemIndex}.originState`),
    watch(`pickups.${itemIndex}.originCtry`),
    props.location
  ]);

  const country = useMemo(() => {
    setStates(getStates());
    const country = getValues(`pickups.${itemIndex}.originCtry`);
    return country ?? '';
  }, [watch(`pickups.${itemIndex}.originCtry`)]);

  return (
    <Stack spacing={3} width={"100%"}>
      {!props.confirm &&
        <>
          <Typography>Add Pickup Address</Typography>
          <FormInput
            name={`pickups.${itemIndex}.originCity`}
            label="City"
            type="text"
            required
          />
          <FormInput
            name={`pickups.${itemIndex}.originState`}
            label="State"
            select
            required
          >
            {states.map((state) => {
              return (
                <MenuItem key={state.acronym} value={state.acronym}>
                  {state.name}
                </MenuItem>
              );
            })}
          </FormInput>
          <FormInput
            name={`pickups.${itemIndex}.originCtry`}
            label="Country"
            select
            required
          >
            {countries.map((state) => {
              return (
                <MenuItem key={state.acronym} value={state.acronym}>
                  {state.name}
                </MenuItem>
              );
            })}
          </FormInput>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Controller
              control={control}
              name={`pickups.${itemIndex}.targetShipEarly`}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <DateTimePicker
                  {...field}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  timezone="UTC"
                  minDate={minEarlyDate}
                  inputRef={ref}
                  sx={{ width: "100%" }}
                  label="Pickup Date"
                />
              )}
            />
          </Box>
        </>
      }
      <Box>
        <Typography fontWeight={600} pb={2}>
          Alternative Cities
        </Typography>
        {alternativeCities?.length > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {alternativeCities.map((city) => (
              <FormControlLabel
                key={city._id}
                control={
                  <Checkbox
                    checked={
                      watch(`pickups.${itemIndex}.alternativeCities`)?.some(
                        (altCity) => altCity._id === city._id
                      ) || false
                    }
                    onChange={(event) => {
                      const selectedCities = watch(`pickups.${itemIndex}.alternativeCities`) || [];
                      if (event.target.checked) {
                        setValue(
                          `pickups.${itemIndex}.alternativeCities`,
                          [...selectedCities, city]
                        );
                      } else {
                        setValue(
                          `pickups.${itemIndex}.alternativeCities`,
                          selectedCities.filter((selectedCity) => selectedCity._id !== city._id)
                        );
                      }
                    }}                                        
                  />
                }
                label={`${city.city}, ${city.state}, ${city.ctry}`}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No alternative cities available.
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
