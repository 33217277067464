export const shipmentsFields = [
    { name: "response.body._id", label: "Shipment Id" },
    { name: "response.body.primaryReference", label: "Primary Reference" },
    { name: "response.body.poNumber", label: "PO Number" },
    { name: "response.body.created.by", label: "User ID" },    
    { name: "timestamp", label: "Timestamp", inputType: 'date', datatype: 'date' },
    // { name: "response.body.ordersNumbers", label: "Orders Numbers" },
    // { name: "response.body.pickups.originName", label: "Origin Name" },
    // { name: "response.body.pickups.originAddr1", label: "Address 1" },
    // { name: "response.body.pickups.originAddr2", label: "Address 2" },
    // { name: "response.body.pickups.originCity", label: "Origin City" },
    // { name: "response.body.pickups.originState", label: "Origin State" },
    // { name: "response.body.pickups.originCtry", label: "Origin Country" },
    // { name: "response.body.pickups.targetShipEarly", label: "Target Ship Early" },
    // { name: "response.body.pickups.targetShipLate", label: "Target Ship Late" },
    // { name: "response.body.stops.destName", label: "Destination Name" },
    // { name: "response.body.stops.destAddr1", label: "Address 1" },
    // { name: "response.body.stops.destAddr2", label: "Address 2" },
    // { name: "response.body.stops.destCity", label: "Destination City" },
    // { name: "response.body.stops.destState", label: "Destination State" },
    // { name: "response.body.stops.destCtry", label: "Destination Country" },
    // { name: "response.body.stops.targetDeliveryEarly", label: "Target Delivery Early" },
    // { name: "response.body.stops.targetDeliveryLate", label: "Target Delivery Late" },
  ];
  

export const locationsFields = [
    { name: "response.body._id", label: "Location Id" },
    { name: "response.body.name", label: "Name" },
    { name: "response.body.addr1", label: "Address 1" },
    { name: "response.body.addr2", label: "Address 2" },
    { name: "response.body.city", label: "City" },
    { name: "response.body.state", label: "State" },
    { name: "response.body.zip", label: "ZIP" },
    { name: "response.body.refType", label: "Ref Type" },    
    { name: "timestamp", label: "Timestamp", inputType: 'date', datatype: 'date' },
];

export const usersFields = [
    { name: "response.body._id", label: "User Id" },
    { name: "response.body.firstname", label: "First Name" },
    { name: "response.body.lastname", label: "Last Name" },
    { name: "response.body.email", label: "Email" },
    { name: "response.body.role.name", label: "Role Name" },    
    { name: "timestamp", label: "Timestamp", inputType: 'date', datatype: 'date' },
];