import { getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid';
import { format, parse } from 'date-fns';

export const customNumericOperators = getGridNumericOperators().map(operator => {
  switch (operator.value) {
    case '=':
      return {
        ...operator,
        label: 'equal',
        getApplyFilterFn: (filterItem) => {
          if (!filterItem.value) {
            return null;
          }
          return ({ value }) => value === Number(filterItem.value);
        },
      };
    case '>':
      return { ...operator, label: 'greater than' };
    case '<':
      return { ...operator, label: 'less than' };
    case '>=':
      return { ...operator, label: 'greater than or equal' };
    case '<=':
      return { ...operator, label: 'less Than or equal' };
    case '!=':
      return { ...operator, label: 'not equal' };
    default:
      return operator;
  }
});
