import {
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

export function TenantModules({ modules }) {
  return (
    <Card
      sx={{
        py: ".5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
      elevation={0}
    >
      <CardHeader title={"Modules"} sx={{ "&.MuiCardHeader-root": { p: 0 } }} />
      <Grid container sx={{ width: "100%", justifyContent: "center" }}>
        <Grid item xs={3}>
          <FormControlLabel
            sx={{ width: "100%", margin: 0 }}
            control={<Checkbox checked={!!modules.shipments} />}
            label={`Shipments`}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            sx={{ width: "100%", margin: 0 }}
            control={<Checkbox checked={!!modules.schedule} />}
            label={`Schedule`}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            sx={{ width: "100%", margin: 0 }}
            control={<Checkbox checked={!!modules.warehouse} />}
            label={`Warehouse`}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            sx={{ width: "100%", margin: 0 }}
            control={<Checkbox checked={!!modules.spotmarket} />}
            label={`Spotmarket`}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
