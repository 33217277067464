import { Helmet } from "react-helmet-async";
import { LocationForm } from "./form/LocationForm";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationQueries } from "./hooks/useLocationQueries";
import { Box, Paper, Typography } from "@mui/material";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useEffect } from "react";
import { Location } from "src/models/location";

export function UpdateLocationPage() {
  const { locationId } = useParams();

  const { getLocation, isLocationLoading, location, update, isUpdating } =
    useLocationQueries();
  const { setSnackbarMessage } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (locationId) getLocation(locationId);
    else navigate("/locations");
  }, [locationId]);

  const onSave = (location) =>
    update({
      location: Location.toJson(location),
      onCreated: (data) => {
        if (data.errors)
          setSnackbarMessage({
            message: <Box> Error submitting location.</Box>,
            severity: "error",
          });
        else {
          setSnackbarMessage({
            message: `Location ${data.name} successfully updated`,
            severity: "success",
          });
          navigate(`/locations/${data._id}`);
        }
      },
      onFailed: (err) =>
        setSnackbarMessage({
          message: <Box> {`${err.message}`}</Box>,
          severity: "error",
        }),
    });

  return (
    <Paper
      sx={{ maxWidth: { lg: "1000px", xs: "90%" }, margin: "auto", py: 3 }}
      elevation={3}
    >
      <Helmet>
        <title>Create Location</title>
      </Helmet>
      <Box sx={{ maxWidth: "80%", margin: "auto" }}>
        <Typography variant="h4" component="h1" sx={{ mb: "2rem" }}>
          Update Location
        </Typography>
        <LocationForm
          onSave={onSave}
          useFormParams={{ defaultValues: location }}
          isSubimissionLoading={isUpdating}
        />
      </Box>
    </Paper>
  );
}
