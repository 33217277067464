export const transformToMongoQuery = (query) => {


  const operatorsMap = {
    "=": (field, value) => ({ [field]: value }),
    "!=": (field, value) => ({ [field]: { $ne: value } }),
    "<": (field, value) => ({ [field]: { $lt: value } }),
    "<=": (field, value) => ({ [field]: { $lte: value } }),
    ">": (field, value) => ({ [field]: { $gt: value } }),
    ">=": (field, value) => ({ [field]: { $gte: value } }),
    contains: (field, value) => ({ [field]: { $regex: value, $options: "i" } }),
    "does not contain": (field, value) => ({ [field]: { $not: { $regex: value, $options: "i" } } }),
    beginsWith: (field, value) => ({ [field]: { $regex: `^${value}`, $options: "i" } }),
    endsWith: (field, value) => ({ [field]: { $regex: `${value}$`, $options: "i" } }),
    "does not begin with": (field, value) => ({ [field]: { $not: { $regex: `^${value}`, $options: "i" } } }),
    "does not end with": (field, value) => ({ [field]: { $not: { $regex: `${value}$`, $options: "i" } } }),
    isNull: (field) => ({ [field]: null }),
    isNotNull: (field) => ({ [field]: { $ne: null } }),
    in: (field, value) => ({ [field]: { $in: value.split(",") } }),
    notIn: (field, value) => ({ [field]: { $nin: value.split(",") } }),
    between: (field, value) => {
      const [start, end] = value.split(",").map((v) => v.trim());
      return { [field]: { $gte: start, $lte: end } };
    },
    notBetween: (field, value) => {
      const [start, end] = value.split(",").map((v) => v.trim());
      return { [field]: { $not: { $gte: start, $lte: end } } };
    },
  };

  const buildRule = (rule) => operatorsMap[rule.operator]?.(rule.field, rule.value);

  const buildMongoQuery = (query) => {
    if (!query?.rules?.length) return {};
    const combinator = query.combinator === "or" ? "$or" : "$and";
    return { [combinator]: query.rules.map(buildRule), "request.method": {$ne: 'GET'} };
  };

  return buildMongoQuery(query);
};