import { useTenant } from "src/hooks/useTenant";

import { CreateRegularShipmentPage } from "./CreateRegularShipmentPage";
import { CreateSpotmarketShipmentPage } from "./CreateSpotmarketShipmentPage";

export function CreateShipmentPage() {
  const { tenant } = useTenant();
  
  const renderPage = () => {
    
    if (tenant.modules?.spotmarket) 
      return <CreateSpotmarketShipmentPage />

    return <CreateRegularShipmentPage />;
  }

  return (
    <> { renderPage() } </>
  )

}
