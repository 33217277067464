import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Shipment } from "src/models/shipment";

export function useShipmentCombineFormHook(props) {
  const useFormParams = props?.useFormParams;
  const defaultValues = useFormParams?.defaultValues;

  const { setSnackbarMessage } = useSnackbar();

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (shipment) => {
    setIsLoading(true);

    try {
      const { pickups, stops } = Shipment.toJson({ ...shipment, isLocationFormatted: true });

      const data = await props.onSave({ ...shipment, pickups, stops });
      if (data?.errors) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      setSnackbarMessage({
        severity: "error",
        message: error.message,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
    reset,
  };
}

export function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, {
      type: "manual",
      message: error.msg,
    });
  });
}
