import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, IconButton } from "@mui/material";
import {
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center";
import { useAuth } from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function NotificationsPopover() {
  const { session } = useAuth();
  const navigate = useNavigate();

  const onNotificationClick = (message) => {
    const targetUrl = message.cta?.data?.url;
    if (targetUrl) navigate(targetUrl, { replace: true });
  };

  return (
    <>
      <NovuProvider
        subscriberId={session?.user?.id}
        applicationIdentifier={"PnaixzkVpwoc"}
      >
        <PopoverNotificationCenter
          colorScheme={"light"}
          footer={() => {}}
          onNotificationClick={onNotificationClick}
          showUserPreferences={false}
        >
          {({ unseenCount }) => (
            <IconButton size="large">
              <Badge badgeContent={unseenCount} color="info">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
        </PopoverNotificationCenter>
      </NovuProvider>
    </>
  );
}
