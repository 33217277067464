import config from "../config";

const baseUrl = config.apiUrl;

export const getAudits = async (req, res) => {
  try {
    const queryString = new URLSearchParams({ query: JSON.stringify(req) }).toString();
    const uri = new URL(`${baseUrl}/audits?${queryString}`);
    const response = await fetch(uri.toString(), {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
  } catch (error) {
    console.error("Error fetching results:", error);
  };
};
