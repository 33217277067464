import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  TextField
} from "@mui/material";

import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormAccordion } from "src/components/form/FormAccordion";
import { PONumberField } from "./PONumberField/PONumberField";
import { PickupAddressFields } from "./PickupFields";
import { ShipmentServicesFields } from "./ServicesFields";
import { StopAddressFields } from "./StopFields";
import { OrdersNumbersField } from "./orders-number-field/OrdersNumbersField";
import { useAuth } from "src/hooks/useAuth";
import { FormInput } from "src/components/form/FormInput";
import { ShipmentNumbersField } from "./ShipmentNumberField";

export function ShipmentCoreFields(props) {
  const { session } = useAuth();
  const { control, getValues, watch } = useFormContext();

  const isWarehouseManager = session.permissions.IsWarehouseManager;
  const isBackoffice = session.permissions.IsBackOffice;

  const [isTransfer, setIsTransfer] = useState(props.type === 'create' && isWarehouseManager ? true : getValues('isTransfer'));
  const primaryReference = getValues('primaryReference')
  const [isPONumberVisible, setPONumberVisibility] = useState(!getValues('isTransfer'));  

  useEffect(() => {
    const subscription = watch((shipment, { name }) => {
      if (name === 'isTransfer') {
        setIsTransfer(shipment.isTransfer);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={primaryReference ?? "Shipment info"}
        action={
          <FormControlLabel
            // sx={{ maxHeight: "3rem", alignSelf: "end" }}
            labelPlacement="end"
            label={"Transfer"}
            control={
              <Controller
                name={`isTransfer`}
                control={control}
                defaultValue={isWarehouseManager}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Switch
                    {...field}
                    onChange={(event) => {
                      onChange(event);

                      if (isWarehouseManager) {
                        return;
                      }

                      setPONumberVisibility(!getValues("isTransfer"));
                    }}
                    inputRef={ref}
                    checked={field.value}
                  />
                )}
              />
            }
          />
        }
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: '1rem' },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",

        }}
      >
        {/* PO NUMBER */}
        <Stack
          sx={{
            flexDirection: {
              xs: "column",
              md: "row"
            },
            gap: "0.75rem"
          }}
        >
          {!isWarehouseManager && (
            <Box
              sx={{
                display: isPONumberVisible ? "flex" : "none",
                width: "100%",
              }}
            >
              <PONumberField />
            </Box>
          )}
          {isTransfer && (
            <FormInput
              name="transferNumber"
              label="Transfer Number"
              fullWidth
              size='normal'
            />
          )}
          <OrdersNumbersField />

          <ShipmentNumbersField />

        </Stack>

        {!isBackoffice && <FormAccordion title={"Locations"}>
          <Stack direction={{ xs: "column", md: "row" }}>
            <PickupAddressFields />
            <Box sx={{ width: '5%', height: '5%' }} />
            <StopAddressFields />
          </Stack>
        </FormAccordion>}

        {/* <FormAccordion title={"Services"}> */}
        <ShipmentServicesFields />
        {/* </FormAccordion> */}
      </CardContent>
    </Card >
  );
}
