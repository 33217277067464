import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export function useWarehouseFormHook(props) {
  const useFormParams = props?.useFormParams;

  const form = useForm({...useFormParams});
  const {
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
    clearErrors
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);
    try {
      const data = await props.onSave(values);
      if (data.error) {
        updateErrors(data.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {      
      setIsLoading(false);
    }
  };

  const mapFields = (params, prefix = "") => {
    if (!params) return;
    Object.entries(params).forEach(([key, value]) => {
      if (!value) return;

      switch (key) {
        case "contacts":
          value.map((contact) =>
            fieldsContact.some((field) => field.email === contact.email)
              ? null
              : appendContact(contact)
          );
          return;
        case "docks":
          value.map((dock) =>
            fieldsDocks.some((field) => field.dockId === dock.dockId)
              ? null
              : appendDock(dock)
          );
          return;
        case "others":
          value.map((holiday) =>
            fieldsHolidayHours.some((field) => field.day === holiday.day)
              ? null
              : appendHolidayHours(holiday)
          );
          return;
        default:
          break;
      }

      if (typeof value === "object")
        return mapFields(value, `${prefix + key}.`);

      form.setValue(prefix + key, value ?? false);
    });
  };

  // Add use form hook for dynamic objects
  const {
    fields: fieldsContact,
    remove: removeContact,
    append: appendContact,
    move:   moveContact,
  } = useFieldArray({ control: form.control, name: "contacts" });

  const {
    fields: fieldsHolidayHours,
    remove: removeHolidayHours,
    append: appendHolidayHours,
  } = useFieldArray({ control: form.control, name: "operationHours.others" });

  const {
    fields: fieldsDocks,
    remove: removeDock,
    append: appendDock,
  } = useFieldArray({ control: form.control, name: "docks" });

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,

    mapFields,

    fieldsContact,
    removeContact,
    appendContact,
    moveContact,

    fieldsHolidayHours,
    removeHolidayHours,
    appendHolidayHours,

    fieldsDocks,
    removeDock,
    appendDock,
  }
}

function updateErrors(errors, setError) {
  errors.forEach((error) => {
    setError(error.param, { type: "manual", message: error.msg });
  })
}
