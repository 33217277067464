import config from "src/config.js";
import {
  BaseService
} from "./base.service";

const baseUrl = config.apiUrl;
const baseEndpoint = "/city";
const getCityEndpoint = "/city/{id}";

export class CityService extends BaseService {

  async getAll() {
    const uri = new URL(baseUrl + baseEndpoint);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

  async getAllFromLocation(city, state, ctry) {
    const uri = new URL(baseUrl + baseEndpoint + '/city-from-location');

    const response = await fetch(
      uri.toString(),
      this.header({
        method: "POST",
        body: JSON.stringify({city, state, ctry}),
      })
    );

    return await response.json();
  }

  async getById(cityId) {
    const response = await fetch(
      baseUrl + getCityEndpoint.replace("{id}", cityId),
      this.header()
    );
    const result = await response.json();
    return result;
  }

  async create(city) {
    const uri = new URL(`${baseUrl}${baseEndpoint}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: "POST",
        body: JSON.stringify(city),
      })
    );

    if (response.status !== 200) {
      const data = await response.json();
      throw new Error(data.message);
    }

    return await response.json();
  }

  async update(city) {
    const uri = new URL(`${baseUrl}${baseEndpoint}/${city._id}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: "POST",
        body: JSON.stringify(city),
      })
    );

    if (response.status !== 200) {
      const data = await response.json();
      throw new Error(data.message);
    }

    return await response.json();
  }
}