export const equipments = [
  {
    label: "Conestoga",
    dat: "CN",
    ts: "CONG",
    key: "conestoga"
  },
  {
    label: "Flatbed",
    dat: "F",
    ts: "F",
    key: "flatbed"
  },
  {
    label: "Flatbed w/ Tarps",
    dat: "FT",
    ts: "FT",
    key: "flatbedTarps"
  },
  {
    label: "Flatbed w/ Teams",
    dat: "FM",
    ts: "FM",
    key: "flatbedTeams"
  },
  {
    label: "Reefer",
    dat: "R",
    ts: "R",
    key: "reefer"
  },
  {
    label: "Reefer w/ Team ",
    dat: "RM",
    ts: "RM",
    key: "reeferTeam"
  },
  {
    label: "Reefer Hazmat",
    dat: "RZ",
    ts: "RZ",
    key: "reeferHazmat"
  },
  {
    label: "Flatbed Hotshot",
    dat: "FH",
    ts: "FH",
    key: "flatbedHotshot"
  },
  {
    label: "Straight Box Truck",
    dat: "SB",
    ts: "SV",
    key: "straightBoxTruck"
  },
  {
    label: "Power Only",
    dat: "PO",
    ts: "PO",
    key: "powerOnly"
  },
  {
    label: "Van",
    dat: "V",
    ts: "V",
    key: "van"
  },
  {
    label: "Van w/ Team ",
    dat: "VM",
    ts: "VV Team",
    key: "vanTeam"
  },
  {
    label: "Van Hazmat",
    dat: "VZ",
    ts: "VZ",
    key: "vanHazmat"
  },
  {
    label: "Vented Van",
    dat: "VV",
    ts: "VV",
    key: "ventedVan"
  },
  {
    label: "Step Deck ",
    dat: "SD",
    ts: "SD",
    key: "stepDeck"
  },
  {
    label: "Step Deck w/ Ramps",
    dat: "",
    ts: "SDL",
    key: "stepDeckRamps"
  },
  {
    label: "Auto Carrier",
    dat: "AC",
    ts: "AUTO",
    key: "autoCarrier"
  },
  {
    label: "RGN",
    dat: "RG",
    ts: "RGN",
    key: "rgn"
  },
  {
    label: "Lowboy",
    dat: "LB",
    ts: "LB",
    key: "lowboy"
  },
];
