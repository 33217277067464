import { LocationOn } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export function LocationCard() {
  const navigator = useNavigate();
  const onLinkTapped = () => {
    navigator(`/audits/locations`);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "12px",
        padding: "15px",
        height: "20rem",
        width: "18rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <LocationOn sx={{ mt: "30px", mb: "10px" }} fontSize="large" />
      <Typography fontWeight={"bold"} fontSize={"25px"} marginBottom={'8px'}>Locations</Typography>
      <Typography align="center">Location logs containing all of location interactions into the platform</Typography>
      <Tooltip title="Open query" >
        <LoadingButton
          variant="contained"                    
          onClick={onLinkTapped}     
          sx={{ m: "1.8rem", width: "95px", borderRadius: 5, fontSize: "19px" }}
        >
          Query
        </LoadingButton>
      </Tooltip>
    </Card>
  );
}
