import { Box } from "@mui/material"
import { ContactUsForm } from "../components/ContactUsForm"

export const ContactUsContent = (props) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0.5rem',
            bgcolor: '#f5f5f5',
            maxWidth: '600px',
            width: '100%',
            maxHeight: '95vh',
            overflow: 'auto',
            padding: '30px',
        }}>
            <div style={{ marginTop: -30 }}>
                <h2>Support Request</h2>
                <p>
                    For any inquiries or assistance, please fill out the form below and
                    we will get back to you as soon as possible.
                </p>
            </div>
            <div>
                <ContactUsForm isSupportOnly={props.isSupportOnly} name={props.name} email={props.email} phone={props.phone} />
            </div>
        </Box>
    )
}