import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useContactUsQueries } from "../contact-us/useContactUsQueries";
import { LoadingButton } from "@mui/lab";

export const ContactUsForm = ({
  isSupportOnly,
  name,
  email,
  phone,
  issue,
  target,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({ defaultValues: { name, email, phone, issue, target } });
  const { sendContactUs, isSending, issues, getIssues } = useContactUsQueries();

  const [state, setState] = useState({
    apiStatus: "none",
    issue: undefined,
    isExternalIssue: false,
  });

  const onIssueChange = (event) => {
    const issue = event.target.value;
    const isExternal = issue.type === "EXTERNAL";
    setState({ ...state, issue: issue, isExternalIssue: isExternal });
  };

  const save = (data) => {
    setState({ ...state, apiStatus: "none" });

    if (state.isExternalIssue) {
      data.requisition = `${data.target}: ${data.requisition}`;
    }

    sendContactUs(data, {
      onSent: ({ status }) => setState({ ...state, apiStatus: status }),
    });
  };

  useEffect(() => {
    getIssues(isSupportOnly ? "Support" : null);
  }, []);

  useEffect(() => {
    const value = issues?.find((v) => v.label === issue);
    if (value) {
      setState({ ...state, issue: value })
    }
  }, [issues]);

  const Status = ({ value }) => {
    const isSuccess = value === "success";
    return (
      <>
        <Alert severity={isSuccess ? "success" : "error"} sx={{ mt: 1 }}>
          {isSuccess
            ? `Email sent, we're reaching out with you soon.`
            : `An error occurred, please again later.`}
        </Alert>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <Stack spacing={2}>
        {state.apiStatus !== "success" && (
          <>
            <TextField
              name="name"
              label="Name"
              error={Boolean(formErrors.name)}
              helperText={formErrors.name?.message}
              fullWidth
              validation
              {...register("name", {
                required: "Name is required",
              })}
            />
            <TextField
              name="email"
              label="Email address"
              error={Boolean(formErrors.email)}
              helperText={formErrors.email?.message}
              fullWidth
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid format",
                },
              })}
            />
            <TextField
              name="phone"
              label="Phone Number"
              fullWidth
              {...register("phone")}
            />
            <FormControl
              fullWidth
              error={Boolean(formErrors.issuetype?.message)}
            >
              <InputLabel id="issue-label">Issue type</InputLabel>
              <Select
                {...register("issue", {
                  required: "Please, select a issue type",
                })}
                labelId="issue-label"
                id="issue"
                label="Issue type"
                value={state.issue}
                onChange={onIssueChange}
              >
                {issues?.map((option) => (
                  <MenuItem key={option._id} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {state.isExternalIssue && (
              <TextField
                name="target"
                label="Primary Reference"
                error={Boolean(formErrors.target)}
                helperText={formErrors.target?.message}
                fullWidth
                {...register("target", {
                  required: "Primary Reference is required",
                })}
              />
            )}
            <TextField
              name="requisition"
              label="Describe your requisition"
              multiline
              rows={2}
              error={Boolean(formErrors.requisition)}
              helperText={formErrors.requisition?.message}
              fullWidth
              {...register("requisition", {
                required: "Requisition is required",
              })}
            />
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isSending}
              sx={{ py: "0.8rem", mt: "1rem" }}
            >
              Send request
            </LoadingButton>
          </>
        )}
        {state.apiStatus !== "none" && <Status value={state.apiStatus} />}
      </Stack>
    </form>
  );
};
