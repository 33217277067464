import { Clear } from "@mui/icons-material";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";

export function UserContacts() {
    const { control } = useFormContext();
    const {
        fields: fieldsContact,
        remove: removeContact,
        append: appendContact,
    } = useFieldArray({ control: control, name: "contact" });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const types = [{
        value: 'email',
        key: 'email',
        label: 'E-mail'
    }, {
        value: 'tel',
        key: 'tel',
        label: 'Phone'
    }, {
        value: 'other',
        key: 'other',
        label: 'Other'
    }]

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                pb: '5rem'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="h5" sx={{ mt: 2 }}>
                    Contacts
                </Typography>
                <Button
                    sx={{ py: "1rem" }}
                    size="small"
                    variant="text"
                    align="left"
                    onClick={() => appendContact({ name: "", phone: "", email: "" })}
                >
                    + Add Contact
                </Button>
            </Box>
            {fieldsContact.map((contact, index) => (
                <Stack spacing={2} key={contact.id}>
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel id={`contact[${index}].type`}>
                                Contact Type
                            </InputLabel>
                            <Controller
                                control={control}
                                name={`contact[${index}].type`}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="Contact Type"
                                        value={field.value || []}
                                        MenuProps={MenuProps}
                                    >
                                        {types.map(type => (
                                            <MenuItem key={type.key} value={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                        <FormInput
                            name={`contact[${index}].value`}
                            label="Value"
                            required
                            type="text"
                            sx={{ flex: 1 }}
                        />
                        <Button
                            sx={{ ml: 0, mr: 0 }}
                            onClick={() => removeContact(index)}
                        >
                            <Clear />
                        </Button>
                    </Box>
                </Stack>
            ))}
        </Box>
    );
}