import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { shipmentServices } from "../../constants/services";
import { useTenant } from "src/hooks/useTenant";
import { SpotmarketEquipmentsField } from "./SpotmarketEquipmentsField";

export function ShipmentServicesFields() {
  const { watch, getValues, setValue } = useFormContext();
  const [mode, setMode] = useState(getValues("mode"));
  const { tenant } = useTenant();

  const [selectedServiceTab, setSelectedServiceTab] = useState(
    getValues("mode") || '0'
  );

  useEffect(() => {
    const updatedMode = watch("mode")?.toLocaleUpperCase();
    if (updatedMode) {
      setMode(updatedMode);
      setSelectedServiceTab(updatedMode);
    }
  }, [watch("mode")]);

  /**
   * Handle single equipment type selection
   * @param {*} event onChange event
   */ 
  function handleTLEquipmentSelection(event) {
    const equipmentKey = event.target.name
      ?.split("equipment.")
      ?.filter((x) => x)[0];
    const equipments = getValues("equipment");

    Object.keys(equipments).forEach((key) => {
      if (key == equipmentKey)
        return;
      else if (equipments[key]) setValue(`equipment.${key}`, false);
    });
  }

  function ServicesTabs() {
    return (
      <TabList>
        {!mode ? (
          <Tab
            value={"0"}
            key={"0"}
            label={"Select Mode to see available Services"}
          />
        ) : mode == "LTL" ? (
          <Tab
            value={"LTL"}
            key={"LTL"}
            label={"Services"}
            onClick={() => setSelectedServiceTab("LTL")}
          />
        ) : mode == "PAR" ? (
          <Tab
            value={"PAR"}
            key={"PAR"}
            label={"Equipment"}
            disabled={!mode == "PAR"}
            onClick={() => setSelectedServiceTab("PAR")}
          />
        ) : (
          mode == "TL" && (
            <Tab
              value={"TL"}
              key={"TL"}
              label={"Equipment"}
              disabled={!mode == "TL"}
              onClick={() => setSelectedServiceTab("TL")}
            />
          )
        )}
      </TabList>
    );
  }

  return (
    <>
      <Box>
        <TabContext value={selectedServiceTab}>
          {/* TABS */}
          <ServicesTabs />

          {/* SERVICE TABS CONTENT */}
          <TabPanel value="LTL">
            <Grid container columns={{ xs: 3, sm: 6, md: 9 }} columnSpacing={3} rowSpacing={2}>
              {shipmentServices
                .find((servicePerMode) => servicePerMode.mode == "LTL")
                ?.services.map((service, index) => {
                  return (
                    <ServiceField
                      service={service}
                      key={`${service.name}${index}`}
                    />
                  );
                })}
            </Grid>
          </TabPanel>

          {tenant?.modules?.spotmarket  ? (
            <SpotmarketEquipmentsField />
          ) : (
            <TabPanel value="TL">
              <Grid container columns={{ xs: 3, sm: 6, md: 9 }} columnSpacing={3} rowSpacing={2}>
                {shipmentServices
                  .find((servicePerMode) => servicePerMode.mode == "TL")
                  ?.services.map((service, index) => {
                    return (
                      <ServiceField
                        service={service}
                        key={`${service.name}${index}`}
                        onChange={handleTLEquipmentSelection}
                      />
                    );
                  })}
              </Grid>
            </TabPanel>
          )}

          <TabPanel value="PAR">
            <Grid container columns={{ xs: 3, sm: 6, md: 9 }} columnSpacing={3} rowSpacing={2}>
              {shipmentServices
                .find((servicePerMode) => servicePerMode.mode == "PAR")
                ?.services.map((service, index) => {
                  return (
                    <ServiceField
                      service={service}
                      key={`${service.name}${index}`}
                      onChange={handleTLEquipmentSelection}
                    />
                  );
                })}
            </Grid>
          </TabPanel>

          <TabPanel
            value="0"
            sx={{ "&.MuiTabPanel-root": { display: "none" } }}
          />
        </TabContext>
      </Box>
    </>
  );
}

export function ServiceField({ service, onChange }) {
  const { control } = useFormContext();

  return (
    <Grid item xs={3}>
      <Controller
        name={service.name}
        control={control}
        defaultValue={false}
        render={({ field }) => {
          return (
            <FormControlLabel
              sx={{ width: "100%", margin: 0 }}
              control={
                <Checkbox
                  {...field}
                  checked={!!field.value}
                  onChange={(e) => {
                    if(onChange) onChange(e);
                    field.onChange(e);
                  }}
                />
              }
              label={service.label}
            />
          );
        }}
      />
    </Grid>
  );
}
