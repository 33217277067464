import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FormAccordion } from "src/components/form/FormAccordion";
import { valueGetter } from "../details-drawer/DetailsDrawer";
import { DeleteForeverOutlined, DragIndicator, Edit } from "@mui/icons-material";
import React, { useMemo } from "react";
import { fDateString } from "src/utils/formatTime";
import { useFormContext } from "react-hook-form";

export const LocationListItem = ({ location, index, onEditTapped, onRemoveTapped, title, disableActions, itemBuilder }) => {
    const { getValues, watch } = useFormContext();

    const items = useMemo(() => {
        const values = getValues('items');

        return values.filter((item, index) => location.items?.some(i => i === item._id ?? index) ?? []);
    }, [watch('items'), location.items]);

    return (
        <FormAccordion
            defaultExpanded={false}
            title={title}
            fontWeight={800}
            fontSize={18}
            sx={{
                mt: '0.5rem'
            }}
            header={
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >

                    <Box sx={{ display: 'flex', gap: '0.4rem' }}>
                        {!disableActions && <DragIndicator sx={{ cursor: 'grab' }} />}
                        <Typography>{title}</Typography>
                    </Box>
                    {!disableActions && (<Box>
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation();
                            onEditTapped(index);
                        }}>
                            <Edit />
                        </Button>
                        <Button color="error"
                            onClick={(event) => {
                                event.stopPropagation();
                                onRemoveTapped(index);
                            }}
                        ><DeleteForeverOutlined /></Button>
                    </Box>)}
                </Box>
            }
        >
            <Stack direction={"column"} gap={1}>
                <Box
                    sx={{
                        display: {
                            md: "flex",
                        },
                        gap: "0.5rem",
                    }}
                >
                    <Typography fontWeight={600}>Address 1:</Typography>
                    <Typography>{valueGetter(location?.address1)}</Typography>
                </Box>
                <Box
                    sx={{
                        display: {
                            md: "flex",
                        },
                        gap: "0.5rem",
                    }}
                >
                    <Typography fontWeight={600}>Address 2:</Typography>
                    <Typography>{valueGetter(location?.address2)}</Typography>
                </Box>
                <Box display={"flex"} gap={"0.5rem"} justifyContent={"space-between"}>
                    <Box display={"flex"} gap={"0.5rem"}>
                        <Typography fontWeight={600}>City:</Typography>
                        <Typography>{valueGetter(location?.city)}</Typography>
                    </Box>
                    <Box display={"flex"} gap={"0.5rem"}>
                        <Typography fontWeight={600}>State:</Typography>
                        <Typography>{valueGetter(location?.state)}</Typography>
                    </Box>
                    <Box display={"flex"} gap={"0.5rem"}>
                        <Typography fontWeight={600}>ZIP:</Typography>
                        <Typography>{valueGetter(location?.zip)}</Typography>
                    </Box>
                    <Box display={"flex"} gap={"0.5rem"}>
                        <Typography fontWeight={600}>Country:</Typography>
                        <Typography>{valueGetter(location?.country)}</Typography>
                    </Box>
                </Box>
                <Divider />
                <Box marginY={"0.8rem"}>
                    <Typography fontWeight={600} fontSize={18} marginBottom={"0.5rem"}>
                        Target
                    </Typography>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"0.5rem"}>
                            <Typography fontWeight={600}>Early:</Typography>
                            <Typography>{fDateString(location.targetEarly)}</Typography>
                        </Box>
                        <Box display={"flex"} gap={"0.5rem"}>
                            <Typography fontWeight={600}>Late:</Typography>
                            <Typography>{fDateString(location.targetLate)}</Typography>
                        </Box>
                    </Box>
                    <Box display={"flex"} marginTop={"0.5rem"} gap={"0.5rem"}>
                        <Typography fontWeight={600}>Timezone:</Typography>
                        <Typography fontSize={14} marginTop={0.3}>
                            {valueGetter(location?.timezone)}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box marginY={"0.8rem"}>
                    <Typography fontWeight={600} fontSize={18} marginBottom={"0.5rem"}>
                        Contact
                    </Typography>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} gap={"0.5rem"}>
                            <Typography fontWeight={600}>Name:</Typography>
                            <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.name)}</Typography>
                        </Box>
                        <Box display={"flex"} gap={"0.5rem"}>
                            <Typography fontWeight={600}>Email:</Typography>
                            <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.email)}</Typography>

                        </Box>
                        <Box display={"flex"} gap={"0.5rem"}>
                            <Typography fontWeight={600}>Phone:</Typography>
                            <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.phone)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box marginY={"0.8rem"}>
                    <Typography fontWeight={600} fontSize={18} marginBottom={"0.5rem"}>
                        Items
                    </Typography>
                    {items.map((item, index) => {
                        let description = [item.description];
                        if (item.primaryReference) description = [item.primaryReference, item.description];
                        else if (item.itemId) description = [item.itemId, item.description];

                        return (
                            <Box display={"flex"} justifyContent={"space-between"} gap={'0.5rem'}>
                                <Box display={"flex"} flexGrow={1} gap={"0.5rem"}>
                                    <Typography>{description.join(' - ')}</Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Stack>
        </FormAccordion>
    );
};