import { Card, CardHeader, FormControl, FormControlLabel, Switch, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const Preferences = () => {
    const { control } = useFormContext()
    return (
        <Card
            variant="outlined"
            sx={{
                borderRadius: "12px",
                padding: "1rem",
                height: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                title={
                    <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                        {"Preferences"}
                    </Typography>
                }
                sx={{
                    "&.MuiCardHeader-root": {
                        p: 0,
                        mb: "3%"
                    },
                }}
            />
            <FormControl sx={{ gap: "0.5rem" }}>
                {/* <Controller
            name={`preferences.bolEmail`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                display={'flex'} sx={{ width: "100%", alignItems: "center", }}
                control={<Switch {...field} checked={!!field.value} />}
                label={"Receive email when BOL is available?"}
              />
            )}
          /> */}
                <Controller
                    name={`preferences.combinedEmail`}
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            display={'flex'} sx={{ width: "100%", alignItems: "center", }}
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"Receive email when your shipment is combined"}
                        />
                    )}
                />
                <Controller
                    name={`preferences.pendingEmail`}
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            display={'flex'} sx={{ width: "100%", alignItems: "center", }}
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"Receive email when your shipment is pending"}
                        />
                    )}
                />
                <Controller
                    name={`preferences.invalidPoEmail`}
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            display={'flex'} sx={{ width: "100%", alignItems: "center", }}
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"Receive email when your shipment has an invalid PO Number "}
                        />
                    )}
                />
            </FormControl>
        </Card >
    );
}