import { FormInput } from "src/components/form/FormInput";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSpotmarketPackageForm } from "src/pages/shipments/context/SpotmarketPackageContext";
import { useTenant } from "src/hooks/useTenant";
import { ShipmentUtils } from "src/utils";

export function SpotmarketPackageBasicInfo({ isSupplierManager }) {
  const { watch, control } = useFormContext();
  const { tenant } = useTenant();
  
  const { packageIndex } = useSpotmarketPackageForm();
  const poNumbers = watch("poNumber");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        mb: "1rem",
      }}
    >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="caption" fontSize={"1.2rem"} fontWeight={600}>
        Basic Info
      </Typography>
      {/* <UploadPackageSlipInput/> */}

    </Box>
      <Controller
        control={control}
        name={`items.${packageIndex}.description`}
        render={({ field }) => (
          <TextField label="Description" {...field} fullWidth required />
        )}
      />
    </Box>
  );
}
